import React from "react";
import { Card } from "antd";
import {
  KeyOutlined,
  ShoppingOutlined,
  UserOutlined,
  AppstoreOutlined,
  HistoryOutlined,
  TagsOutlined,
  AppstoreAddOutlined,
  FilterOutlined,
  SettingOutlined,
  FileTextOutlined,
  ScheduleOutlined,
  DatabaseOutlined,
} from "@ant-design/icons";
import "./Dashboard.scss";

const Dashboard = () => {
  return (
    <div className="dashboard-container">
      <h1>Witaj, co chcesz dzisiaj zobaczyć?</h1>
      <div className="dashboard-grid">
        {/* <a href="/admin/zarzadzanie-dostepami" className="dashboard-card">
          <Card
            hoverable
            cover={
              <img
                src="/images/CMS/management.png"
                alt="Zarządzanie dostępami"
                className="dashboard-icon"
              />
            }
          >
            <Card.Meta title="Zarządzanie dostępami" />
          </Card>
        </a> */}
        <a href="/admin/products" className="dashboard-card">
          <Card
            hoverable
            cover={
              <img
                src="/images/CMS/product.png"
                alt="Lista produktów"
                className="dashboard-icon"
              />
            }
          >
            <Card.Meta title="Lista produktów" />
          </Card>
        </a>
        <a href="/admin/users" className="dashboard-card">
          <Card
            hoverable
            cover={
              <img
                src="/images/CMS/users.png"
                alt="Lista użytkowników"
                className="dashboard-icon"
              />
            }
          >
            <Card.Meta title="Lista użytkowników" />
          </Card>
        </a>
        <a href="/admin/category" className="dashboard-card">
          <Card
            hoverable
            cover={
              <img
                src="/images/CMS/category.png"
                alt="Lista kategorii"
                className="dashboard-icon"
              />
            }
          >
            <Card.Meta title="Lista kategorii" />
          </Card>
        </a>

        <a href="/admin/log" className="dashboard-card">
          <Card
            hoverable
            cover={
              <img
                src="/images/CMS/changes.png"
                alt="Lista zmian produktów"
                className="dashboard-icon"
              />
            }
          >
            <Card.Meta title="Lista zmian produktów" />
          </Card>
        </a>
        <a href="/admin/supercategory" className="dashboard-card">
          <Card
            hoverable
            cover={
              <img
                src="/images/CMS/supercategory.png"
                alt="Lista superkategorii"
                className="dashboard-icon"
              />
            }
          >
            <Card.Meta title="Lista superkategorii" />
          </Card>
        </a>
        <a href="/admin/collections" className="dashboard-card">
          <Card
            hoverable
            cover={
              <img
                src="/images/CMS/collection.png"
                alt="Lista kolekcji"
                className="dashboard-icon"
              />
            }
          >
            <Card.Meta title="Lista kolekcji" />
          </Card>
        </a>
        <a href="/admin/types" className="dashboard-card">
          <Card
            hoverable
            cover={
              <img
                src="/images/CMS/types.png"
                alt="Lista typów"
                className="dashboard-icon"
              />
            }
          >
            <Card.Meta title="Lista typów" />
          </Card>
        </a>
      </div>
    </div>
  );
};

export default Dashboard;
