import React from 'react';
import './IconTextGradient.scss';

interface IconTextGradientProps {
    icon: string;
    text: string;
    gradientDirection?: 'to right' | 'to left';
}

const IconTextGradient: React.FC<IconTextGradientProps> = ({ icon, text, gradientDirection = 'to right' }) => {
    const gradientStyle = {
        '--gradient-direction': gradientDirection,
    } as React.CSSProperties;

    return (
        <div className="icon-text-gradient" style={gradientStyle}>
            <span className="material-symbols-outlined">{icon}</span>
            <span className="text">{text}</span>
        </div>
    );
};

export default IconTextGradient;
