import { useState } from "react";
import ItemCard from "../../../components/ItemCard/ItemCard";
import "./Catalog.scss";

const products = [
  {
    imageSrc: "/images/Pierscionek.jpeg",
    title: "PIERŚCIONEK WHITELUXURY™ Z BRYLANTEM 4.40CT",
    price: "430 500 PLN",
    content: "Kup teraz",
    path: "",
    showButton: false,
  },
  {
    imageSrc: "/images/rolexGMTMaster.jpeg",
    title: "ZEGAREK ROLEX GMT MASTER II ROOT BEER",
    price: "20 750 EUR",
    content: "Kup teraz",
    path: "",
    showButton: false,
  },
  {
    imageSrc: "/images/CapsuleCollectionLimited.png",
    title: 'BRANSOLETKA "CAPSULE COLLECTION LIMITED™"',
    price: "24 000 PLN",
    content: "Kup teraz",
    path: "",
    showButton: false,
  },
  {
    imageSrc: "/images/KolczykiInvisible.png",
    title: 'KOLCZYKI Z DIAMENTAMI "INVISIBLE™"',
    price: "9 900 PLN",
    content: "Kup teraz",
    path: "",
    showButton: false,
  },
  {
    imageSrc: "/images/Pierscionek.jpeg",
    title: "PIERŚCIONEK WHITELUXURY™ Z BRYLANTEM 4.40CT",
    price: "430 500 PLN",
    content: "Kup teraz",
    path: "",
    showButton: false,
  },
  {
    imageSrc: "/images/rolexGMTMaster.jpeg",
    title: "ZEGAREK ROLEX GMT MASTER II ROOT BEER",
    price: "20 750 EUR",
    content: "Kup teraz",
    path: "",
    showButton: false,
  },
  {
    imageSrc: "/images/CapsuleCollectionLimited.png",
    title: 'BRANSOLETKA "CAPSULE COLLECTION LIMITED™"',
    price: "24 000 PLN",
    content: "Kup teraz",
    path: "",
    showButton: false,
  },
  {
    imageSrc: "/images/KolczykiInvisible.png",
    title: 'KOLCZYKI Z DIAMENTAMI "INVISIBLE™"',
    price: "9 900 PLN",
    content: "Kup teraz",
    path: "",
    showButton: false,
  },
  {
    imageSrc: "/images/Pierscionek.jpeg",
    title: "PIERŚCIONEK WHITELUXURY™ Z BRYLANTEM 4.40CT",
    price: "430 500 PLN",
    content: "Kup teraz",
    path: "",
    showButton: false,
  },
  {
    imageSrc: "/images/rolexGMTMaster.jpeg",
    title: "ZEGAREK ROLEX GMT MASTER II ROOT BEER",
    price: "20 750 EUR",
    content: "Kup teraz",
    path: "",
    showButton: false,
  },
  {
    imageSrc: "/images/CapsuleCollectionLimited.png",
    title: 'BRANSOLETKA "CAPSULE COLLECTION LIMITED™"',
    price: "24 000 PLN",
    content: "Kup teraz",
    path: "",
    showButton: false,
  },
  {
    imageSrc: "/images/KolczykiInvisible.png",
    title: 'KOLCZYKI Z DIAMENTAMI "INVISIBLE™"',
    price: "9 900 PLN",
    content: "Kup teraz",
    path: "",
    showButton: false,
  },
  {
    imageSrc: "/images/Pierscionek.jpeg",
    title: "PIERŚCIONEK WHITELUXURY™ Z BRYLANTEM 4.40CT",
    price: "430 500 PLN",
    content: "Kup teraz",
    path: "",
    showButton: false,
  },
  {
    imageSrc: "/images/rolexGMTMaster.jpeg",
    title: "ZEGAREK ROLEX GMT MASTER II ROOT BEER",
    price: "20 750 EUR",
    content: "Kup teraz",
    path: "",
    showButton: false,
  },
  {
    imageSrc: "/images/CapsuleCollectionLimited.png",
    title: 'BRANSOLETKA "CAPSULE COLLECTION LIMITED™"',
    price: "24 000 PLN",
    content: "Kup teraz",
    path: "",
    showButton: false,
  },
  {
    imageSrc: "/images/KolczykiInvisible.png",
    title: 'KOLCZYKI Z DIAMENTAMI "INVISIBLE™"',
    price: "9 900 PLN",
    content: "Kup teraz",
    path: "",
    showButton: false,
  },
  {
    imageSrc: "/images/Pierscionek.jpeg",
    title: "PIERŚCIONEK WHITELUXURY™ Z BRYLANTEM 4.40CT",
    price: "430 500 PLN",
    content: "Kup teraz",
    path: "",
    showButton: false,
  },
  {
    imageSrc: "/images/rolexGMTMaster.jpeg",
    title: "ZEGAREK ROLEX GMT MASTER II ROOT BEER",
    price: "20 750 EUR",
    content: "Kup teraz",
    path: "",
    showButton: false,
  },
  {
    imageSrc: "/images/CapsuleCollectionLimited.png",
    title: 'BRANSOLETKA "CAPSULE COLLECTION LIMITED™"',
    price: "24 000 PLN",
    content: "Kup teraz",
    path: "",
    showButton: false,
  },
  {
    imageSrc: "/images/KolczykiInvisible.png",
    title: 'KOLCZYKI Z DIAMENTAMI "INVISIBLE™"',
    price: "9 900 PLN",
    content: "Kup teraz",
    path: "",
    showButton: false,
  },
  {
    imageSrc: "/images/Pierscionek.jpeg",
    title: "PIERŚCIONEK WHITELUXURY™ Z BRYLANTEM 4.40CT",
    price: "430 500 PLN",
    content: "Kup teraz",
    path: "",
    showButton: false,
  },
  {
    imageSrc: "/images/rolexGMTMaster.jpeg",
    title: "ZEGAREK ROLEX GMT MASTER II ROOT BEER",
    price: "20 750 EUR",
    content: "Kup teraz",
    path: "",
    showButton: false,
  },
  {
    imageSrc: "/images/CapsuleCollectionLimited.png",
    title: 'BRANSOLETKA "CAPSULE COLLECTION LIMITED™"',
    price: "24 000 PLN",
    content: "Kup teraz",
    path: "",
    showButton: false,
  },
  {
    imageSrc: "/images/KolczykiInvisible.png",
    title: 'KOLCZYKI Z DIAMENTAMI "INVISIBLE™"',
    price: "9 900 PLN",
    content: "Kup teraz",
    path: "",
    showButton: false,
  },
  {
    imageSrc: "/images/Pierscionek.jpeg",
    title: "PIERŚCIONEK WHITELUXURY™ Z BRYLANTEM 4.40CT",
    price: "430 500 PLN",
    content: "Kup teraz",
    path: "",
    showButton: false,
  },
  {
    imageSrc: "/images/rolexGMTMaster.jpeg",
    title: "ZEGAREK ROLEX GMT MASTER II ROOT BEER",
    price: "20 750 EUR",
    content: "Kup teraz",
    path: "",
    showButton: false,
  },
  {
    imageSrc: "/images/CapsuleCollectionLimited.png",
    title: 'BRANSOLETKA "CAPSULE COLLECTION LIMITED™"',
    price: "24 000 PLN",
    content: "Kup teraz",
    path: "",
    showButton: false,
  },
  {
    imageSrc: "/images/KolczykiInvisible.png",
    title: 'KOLCZYKI Z DIAMENTAMI "INVISIBLE™"',
    price: "9 900 PLN",
    content: "Kup teraz",
    path: "",
    showButton: false,
  },
  {
    imageSrc: "/images/Pierscionek.jpeg",
    title: "PIERŚCIONEK WHITELUXURY™ Z BRYLANTEM 4.40CT",
    price: "430 500 PLN",
    content: "Kup teraz",
    path: "",
    showButton: false,
  },
  {
    imageSrc: "/images/rolexGMTMaster.jpeg",
    title: "ZEGAREK ROLEX GMT MASTER II ROOT BEER",
    price: "20 750 EUR",
    content: "Kup teraz",
    path: "",
    showButton: false,
  },
  {
    imageSrc: "/images/CapsuleCollectionLimited.png",
    title: 'BRANSOLETKA "CAPSULE COLLECTION LIMITED™"',
    price: "24 000 PLN",
    content: "Kup teraz",
    path: "",
    showButton: false,
  },
  {
    imageSrc: "/images/KolczykiInvisible.png",
    title: 'KOLCZYKI Z DIAMENTAMI "INVISIBLE™"',
    price: "9 900 PLN",
    content: "Kup teraz",
    path: "",
    showButton: false,
  },
];

type CatalogProps = {
  title: string;
  photo: boolean;
  number?: number;
};

export default function Collections({ title, photo, number }: CatalogProps) {
  const [visibleCount, setVisibleCount] = useState(8);

  const handleShowMore = () => {
    setVisibleCount((prev) => prev + 8);
  };

  return (
    <div className="CollectionsPage">
      <div className="CatalogHeading">
        <h1>{title}</h1>
      </div>
      <div className="grid-container">
        {products.slice(0, visibleCount).map((product, index) => {
          if (photo && index === number) {
            return (
              <>
                <div key={index} className="large-image">
                  <img
                    alt="Modelka"
                    src="/images/catalog.png"
                    className="full-image"
                  />
                </div>
                <div key={index} className="grid-item">
                  <ItemCard
                    key={index}
                    imageSrc={product.imageSrc}
                    title={product.title}
                    price={product.price}
                    content={product.content}
                    path={product.path}
                    showButton={product.showButton}
                    variant="catalog"
                  />
                </div>
              </>
            );
          }
          return (
            <div key={index} className="grid-item">
              <ItemCard
                key={index}
                imageSrc={product.imageSrc}
                title={product.title}
                price={product.price}
                content={product.content}
                path={product.path}
                showButton={product.showButton}
                variant="catalog"
              />
            </div>
          );
        })}
      </div>
      {visibleCount < products.length && (
        <div className="show-more-container">
          <p>
            {visibleCount} z {products.length} produktów
          </p>
          <button onClick={handleShowMore} className="show-more-button">
            Pokaż więcej
          </button>
        </div>
      )}
      <hr></hr>
    </div>
  );
}
