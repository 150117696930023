import { Fragment, useMemo } from "react";
import Main from "../Main/Main";
import NavbarAdmin from "./NavbarAdmin";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import "./AdminLayout.scss";
import { Breadcrumb, Layout, Menu, message, Button } from "antd";
import { Header, Content } from "antd/es/layout/layout";
import Sider from "antd/es/layout/Sider";
import {
  UserOutlined,
  LaptopOutlined,
  NotificationOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import React from "react";

const breadcrumbNameMap = {
  "/admin": "Dashboard",
  "/admin/products": "Products",
  "/admin/log": "Logs",
  "/admin/category": "Category",
  "/admin/supercategory": "Supercategory",
  "/admin/users": "Users",
  "/admin/collections": "Collections",
  "/admin/types": "Types",
};

export default function AdminLayout() {
  const location = useLocation();
  const pathSnippets = location.pathname.split("/").filter((i) => i);
  const navigate = useNavigate();

  const breadcrumbs = useMemo(() => {
    const extraBreadcrumbItems = pathSnippets.map((_, index) => {
      const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
      const breadcrumbTitle =
        breadcrumbNameMap[url as keyof typeof breadcrumbNameMap] || url;
      return {
        title: breadcrumbTitle || url,
        path: url,
      };
    });
    return [{ title: "Home", path: "/" }].concat(extraBreadcrumbItems);
  }, [pathSnippets]);

  const handleMenuClick = (e: any) => {
    navigate(e.key);
  };

  const handleLogout = () => {
    message.success("Pomyślnie wylogowano");
    // navigate("/login");
  };

  return (
    <Layout>
      <Header
        style={{
          display: "flex",
          alignItems: "center",
          backgroundColor: "black",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{ display: "flex", alignItems: "center" }}
          className="header"
        >
          <img
            src="/images/MMGoldLogo.png"
            alt="Logo"
            style={{ height: 64, marginRight: 16 }}
          />
          <h1
            style={{
              color: "white",
              fontFamily: "Gotu",
            }}
          >
            Panel Administracyjny
          </h1>
        </div>

        <LogoutOutlined
          onClick={handleLogout}
          style={{
            fontSize: "24px",
            color: "white",
            cursor: "pointer",
            marginLeft: "auto",
          }}
        />
      </Header>
      <Layout>
        <Sider width={200}>
          <Menu
            mode="inline"
            defaultSelectedKeys={["/admin"]}
            selectedKeys={[location.pathname]}
            defaultOpenKeys={["/admin"]}
            style={{
              height: "100%",
              borderRight: 0,
            }}
            items={[
              { key: "/admin", label: "Dashboard" },
              { key: "/admin/products", label: "Products" },
              { key: "/admin/log", label: "Logs" },
              { key: "/admin/category", label: "Category" },
              { key: "/admin/supercategory", label: "Supercategory" },
              { key: "/admin/users", label: "Users" },
              { key: "/admin/collections", label: "Collections" },
              { key: "/admin/types", label: "Types" },
            ]}
            onClick={handleMenuClick}
          />
        </Sider>
        <Layout style={{ padding: "0 24px 24px" }}>
          <Breadcrumb style={{ margin: "16px 0" }}>
            {breadcrumbs.map((breadcrumb, index) => (
              <Breadcrumb.Item key={index}>{breadcrumb.title}</Breadcrumb.Item>
            ))}
          </Breadcrumb>
          <Content
            className="adminContent"
            style={{
              padding: 24,
              margin: 0,
              minHeight: 280,
              background: "white",
              borderRadius: "10px",
            }}
          >
            <Outlet />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
}
