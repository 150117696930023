import { useApi } from "./useApi";
import { ILog } from "../types/ILog";
import { useAuth } from "./useAuth";
import { useAsync } from "./useAsync";
import { IStatus } from "../types/IStatus";

export interface ILogResponse {
  // message: string;
  logs: ILog[];
}

export const useLog = () => {
  const { api } = useApi();
  const { auth } = useAuth();

  const log = {
    // getAll: useAsync(async (status?: string): Promise<ILog[]> => {
    //   // auth.validate();
    //   const params = status ? { params: { status } } : {};
    //   const response = await api.get<ILog[]>("/admin/log", params);
    //   return response;
    // }),

    getAllStatus: useAsync(async (statusId: number): Promise<ILog[]> => {
      // auth.validate();
      const response = await api.get<ILog[]>("/admin/log/status", {
        params: { status: statusId },
      });
      return response;
    }),

    get: useAsync(async (id: number): Promise<ILog> => {
      // auth.validate();
      const response = await api.get<ILog>(`/admin/log/${id}`);
      return response;
    }),

    update: useAsync(async (id: number, updatedLog: IStatus): Promise<ILog> => {
      // auth.validate();
      const response = await api.put<ILog>(`/admin/log/${id}`, updatedLog);
      return response;
    }),

    delete: useAsync(async (id: number): Promise<void> => {
      // auth.validate();
      await api.delete(`/admin/log/${id}`);
    }),
  };

  return { log };
};
