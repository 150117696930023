import React from "react";
import "./ImageTextSection.scss";
import Text from "../../Text/Text";

interface ImageTextSectionProps {
  imgPath: string;
  title: string;
  text: string;
  path: string;
  content: string;
  variant?: "reverse"; 
}

export default function ImageTextSection(props: ImageTextSectionProps) {
  const { imgPath, title, text, path, content, variant } = props;

  return (
    <div className={`image-text-section ${variant ? `variant-${variant}` : ''}`}>
      <div className="text-section">
        <Text
          title={title}
          text={text}
          showButton={true}
          align="text-left"
          content={content}
          path={path}
        />
      </div>
      <div className="image-section">
        <img src={imgPath} alt={title} />
      </div>
    </div>
  );
}