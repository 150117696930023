import React from "react";
import Page from "../../components/Page/Page";
import "./Cart.scss";
import RecentlySeen from "../../components/RecentlySeen/RecentlySeen";
import PaymentMethods from "../../components/Cart/PaymentMethods/PaymentMethods";
import MatchingProducts from "../../components/Cart/MatchingProducts/MatchingProducts";
import Summary from "../../components/Cart/Summary/Summary";
import AdjustableButton from "../../components/AdjustableButton/AdjustableButton";
export default function Cart() {
  return (
    <Page>
        <div className="summary">
        <h1>KOSZYK</h1>
        <hr />
        <AdjustableButton text={"WRÓĆ DO ZAKUPÓW"} href={"#"} textColor="black" borderColor="black" backgroundColor="white" hoverBackgroundColor="black" hoverTextColor="white" hoverBorderColor="white" />
        </div>
        <Summary />
      <div className="matching">
        <MatchingProducts />
      </div>
      <RecentlySeen />
      <PaymentMethods />
    </Page>
  );
}
