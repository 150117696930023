import AdjustableButton from "../../AdjustableButton/AdjustableButton";
import ProductItem from "./ProductItem/ProductItem";
import "./Summary.scss";
import { FiPhone } from "react-icons/fi";
import { CiCalendarDate } from "react-icons/ci";


export default function Summary() {
  return (
    <div className="SummaryContent">
      <ProductItem />
      <div className="row">
      <div className="gift">
        <input type="checkbox" />
        <p>ZAPAKUJ NA PREZENT</p>
        </div>
        <div className="checkout">
            <div className="checkout-row"><h2>SUMA ZAMÓWIENIA</h2> <h2>9999zł</h2></div>
            <div className="checkout-row"><h2>DOSTAWA</h2> <h2>bezplatne</h2></div>
            <div className="checkout-row"><h2>CAŁKOWITY KOSZT ZAMÓWIENIA</h2><h2>9999zł</h2></div>
            <AdjustableButton text={"przejdź do kasy"} href={"/checkout"} />
        <p className="call">
            <FiPhone /> 
            <a href="tel:+999999999">ZADZWOŃ DO NAS</a></p>
        <p className="visit">
        <CiCalendarDate />
        <a href="#">UMÓW WIZYTĘ W SALONIE</a>
        </p>
        </div>

      </div>
    </div>
  );
}
