import React from 'react';
import './DiamondsLegality.scss';
import Text from '../../Text/Text';

export default function DiamondLegality() {
    return (
        <div className="diamondLegality-content">
            <div className="diamondLegality-text">
                <Text 
                    title={'gwarancja legalności'} 
                    text={'Jakość potwierdzona Certyfikatami wystawianymi przez Międzynarodowego Eksperta Diamentów oraz Kamieni Kolorowych z ukończonymi kursami w HRD w Antwerpii. Nasze certyfikaty to gwarancja dobrego wyboru oraz pełnego bezpieczeństwa.'} 
                    showButton={false} 
                    align={'text-right'} 
                    content={''} 
                    path={''} 
                />
                <img src="./images/HRD.svg" alt="HRD Logo" />
            </div>
            <div className="diamondLegality-image">
                <img src="./images/certyfikat.png" alt="Certyfikat" />
            </div>
        </div>
    );
}