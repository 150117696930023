import React from "react";
import "./Footbar.scss";
import FooterIcons from "./FooterIcons/FooterIcons";
import FooterContent from "./FooterContent/FooterContent";
import FooterSponsors from "./FooterSponsors/FooterSponsors";

export default function Footbar() {
  return (
    <footer className="footbar-content">
      <FooterIcons />
      <FooterContent />
      <FooterSponsors />
    </footer>
  );
}
