import React from "react";
import  "./ShowroomHeading.scss"
export default function ShowroomHeading() {
  return (
    <div className="showroom-heading">
        <div className="showroom-text">
      <h1>NASZE SALONY W WARSZAWIE</h1>
      <p>
        Odkryj skarbce M&M Gold najbliżej siebie. Dojazd w 12 minut z każdego
        miejsca w Warszawie.
      </p>
      </div>
    </div>
  );
}
