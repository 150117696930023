import React from "react";
import "./Departments.scss";
import DepartmentItem from "./DepartmentItem/DepartmentItem";
import IconTextGradient from "../../IconTextGradient/IconTextGradient";

const Departments = () => {
  return (
    <div className="departments">
      <div className="departments-title">NASZE DEPARTAMENTY</div>
      <div className="departments-container">
        <div className="departments-column">
          <DepartmentItem
            title="ZAMÓWIENIA INTERNETOWE"
            phone="ZADZWOŃ POD +48 787 787 777"
          />
          <DepartmentItem
            title="DOM AUKCYJNY ANORAMA"
            phone="ZADZWOŃ POD +48 787 787 777"
          />
          <DepartmentItem
            title="SKUP BRYLANTÓW, BIŻUTERII I ZŁOTA"
            phone="ZADZWOŃ POD +48 787 787 777"
          />
        </div>
        <div className="departments-column">
          <DepartmentItem
            title="WYCENY MIĘDZYNARODOWEGO EKSPERTA"
            phone="ZADZWOŃ POD +48 787 787 777"
          />
          <DepartmentItem
            title="SKUP ZEGARKÓW"
            phone="ZADZWOŃ POD +48 787 787 777"
          />
          <DepartmentItem
            title="SPRZEDAŻ HURTOWA BRYLANTÓW DLA FIRM"
            phone="ZADZWOŃ POD +48 787 787 777"
          />
        </div>
        <div className="customer-service">
          <div className="customer-service-title">BIURO OBSŁUGI KLIENTA</div>
          <div className="customer-service-contact">
            <IconTextGradient
              icon="phone"
              text="ZADZWOŃ POD +48 XXX XXX XXXX"
            />
            <IconTextGradient icon="email" text="E-MAIL" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Departments;
