import React, { useState } from "react";
import "./SmallNav.scss";
import useNavbarVisibility from "../../../hooks/useNavbarVisibility";
import MobileNav from "../MobileNav/MobileNav";
import { SearchOutlined } from "@ant-design/icons";
import NavDrawer from "../NavDrawer/NavDrawer";
import { ReactComponent as ShoppingBagIcon } from "../../../icons/shopping-bag.svg";
import { ReactComponent as HeartIcon } from "../../../icons/heart.svg";

export default function SmallNav() {
  const { isVisible, changeVisibility } = useNavbarVisibility();
  const [isContentVisible, setIsContentVisible] = useState(false);
  const [selectedKey, setSelectedKey] = useState("");

  const toggleContent = (key: string) => {
    if (selectedKey === "") {
      setSelectedKey(key);
      setIsContentVisible(!isContentVisible);
    } else if (selectedKey === key) {
      setSelectedKey("");
      setIsContentVisible(!isContentVisible);
    } else {
      setSelectedKey(key);
    }
  };

  const navItems = [
    { key: "about", label: "O NAS", href: "/about-us" },
    { key: "new", label: "NOWOŚCI", href: "/" },
    {
      key: "products",
      label: "PRODUKTY",
      onClick: () => toggleContent("products"),
    },
    {
      key: "collections",
      label: "KOLEKCJE",
      onClick: () => toggleContent("collections"),
    },
    {
      key: "custom-products",
      label: "PRODUKTY NA ZAMÓWIENIE",
      href: "/",
    },
    { key: "bestsellers", label: "BESTSELLERY", href: "/bestsellers" },
    {
      key: "for-him",
      label: "DLA NIEGO",
      onClick: () => toggleContent("for-him"),
    },
    { key: "about-diamonds", label: "O DIAMENTACH", href: "/about-diamonds" },
  ];

  return (
    <>
      <nav className="smallNav">
        <div className="smallNav_row">
          <div className="smallNav_mobile">
            <div className="smallNav_mobile_left">
              <MobileNav />
              <SearchOutlined />
            </div>
            <div className="smallNav_mobile_center">
              <img
                src={"/images/MMGoldLogo.png"}
                alt={"Logo M&M Gold"}
                height={"100%"}
              />
            </div>
            <div className="smallNav_mobile_right">
              <button
                onClick={() => console.log("Heart!")}
                style={{
                  background: "none",
                  border: "none",
                  // padding: 0,
                  cursor: "pointer",
                }}
              >
                <HeartIcon className="icon" />
              </button>
              <button
                onClick={() => console.log("shopping-bag!")}
                style={{
                  background: "none",
                  border: "none",
                  // padding: 0,
                  cursor: "pointer",
                }}
              >
                <ShoppingBagIcon className="icon" />
              </button>
            </div>
          </div>

          <ul className={"navbar-list"}>
            {navItems.map((item) => (
              <li
                key={item.key}
                className={`navbar-item ${
                  selectedKey === item.key ? "selected" : ""
                }`}
                onClick={item.onClick}
              >
                {item.href ? (
                  <a href={item.href}>{item.label}</a>
                ) : (
                  <span>{item.label}</span>
                )}
              </li>
            ))}
          </ul>

          <div className="navbar-divider"></div>

          <div className="navbar-search">
            <SearchOutlined />
          </div>
        </div>
        <NavDrawer open={isContentVisible} data={selectedKey} />
      </nav>
    </>
  );
}
