import React from "react";
import "./ProductItem.scss";

const CartItems = [
  {
    imageSrc: "./images/matching1.jpeg",
    title: "ZEGAREK ROLEX DATEJUST 36",
    size: "one-size",
    color: "gold",
    quantity: "1",
    price: "46 000zł",
    value: "46 000zł",
  },
  {
    imageSrc: "./images/matching2.jpeg",
    title: "ZEGAREK ROLEX DATEJUST 36",
    size: "one-size",
    color: "gold",
    quantity: "1",
    price: "46 000zł",
    value: "46 000zł",
  },
];

export default function ProductItem() {
  return (
    <div className="ProductItemContent">
      <div className="ProductItemHeading">
        <div className="spacer"></div>
        <p>PRODUKT</p>
        <p>ROZMIAR</p>
        <p>KOLOR</p>
        <p>ILOŚĆ</p>
        <p>CENA</p>
        <p>WARTOŚĆ</p>
        <p>AKCJE</p>
      </div>

      {CartItems.map((item, index) => (
        <div className="ProductItem" key={index}>
          <img src={item.imageSrc} alt={item.title} className="ProductImage" />
          <p>{item.title}</p>
          <p>{item.size}</p>
          <p>{item.color}</p>
          <p>{item.quantity}</p>
          <p>{item.price}</p>
          <p>{item.value}</p>
          <a href="#">Usuń</a>
        </div>
      ))}
    </div>
  );
}
