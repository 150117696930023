import "./ItemCard.scss";
import UnderlinedButton from "../UnderlinedButton/UnderlinedButton";
import { ReactComponent as HeartIcon } from "../../icons/heart.svg";

export default function ItemCard(props: {
  imageSrc: string;
  title: string;
  price: string;
  content: string;
  path: string;
  showButton: boolean;
  variant?: "small" | "large" | "catalog";
}) {
  const {
    imageSrc,
    title,
    price,
    content,
    path,
    showButton,
    variant = "large",
  } = props;

  return (
    <div className={`itemCard ${variant}`}>
      <img src={imageSrc} alt="Product" className="itemCardImage" />
      {/* <Heart2Icon className="icon" /> */}
      <HeartIcon className="icon itemHeartIcon" fill="transparent" />
      <h1>{title}</h1>
      <p className="colorText">PINK GOLD / WHITE GOLD</p>
      <p>{price}</p>
      {showButton && <UnderlinedButton content={content} path={path} />}
    </div>
  );
}
