import { useApi } from "./useApi";
import { useAuth } from "./useAuth";
import { useAsync } from "./useAsync";
import { IProduct } from "../types/IProduct";
import { IStatus } from "../types/IStatus";

export interface IProductResponse {
  product: IProduct;
  status: IStatus;
}

export const useProduct = () => {
  const { api } = useApi();
  const { auth } = useAuth();

  const product = {
    getAll: useAsync(async (): Promise<IProductResponse[]> => {
      const response = await api.get<IProductResponse[]>("/public/product");
      return response;
    }),

    get: useAsync(async (id: number): Promise<IProduct> => {
      const response = await api.get<IProduct>(`/public/product/${id}`);
      return response;
    }),

    create: useAsync(async (newProduct: IProduct): Promise<IProduct> => {
      auth.validate();
      const response = await api.post<IProduct>(
        "/moderator/product",
        newProduct
      );
      return response;
    }),

    update: useAsync(
      async (id: number, updatedProduct: IProduct): Promise<IProduct> => {
        auth.validate();
        const response = await api.put<IProduct>(
          `/moderator/product/${id}`,
          updatedProduct
        );
        return response;
      }
    ),

    delete: useAsync(async (id: number): Promise<void> => {
      auth.validate();
      await api.delete(`/moderator/product/${id}`);
    }),

    encode: async (file: File): Promise<string> => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
      });
    },

    decode: (base64String: string): string => {
      return base64String;
    },
  };

  return { product };
};
