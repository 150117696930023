import React from "react";
import { Form, Input, Button, notification } from "antd";
import { useAuth } from "../../hooks/useAuth";
import { SignInRequest } from "../../interfaces/auth";

type NotificationType = "success" | "info" | "warning" | "error";

export default function SignIn() {
  const { auth } = useAuth();
  const [api, contextHolder] = notification.useNotification();

  const notify = (type: NotificationType) => {
    api[type]({ message: "The icon suggests current status!" });
  };

  const onFinish = async (values: SignInRequest) => {
    try {
      await auth.signin.execute(values.email, values.password);
      notify("success");
    } catch (err) {
      notify("error");
    }
  };

  return (
    <>
      {contextHolder}
      <Form
        name="signin"
        onFinish={onFinish}
        style={{ maxWidth: "300px", margin: "0 auto", marginTop: "100px" }}
      >
        <Form.Item
          name="email"
          rules={[{ required: true, message: "Please input your email!" }]}
        >
          <Input type="email" placeholder="Email" />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: "Please input your password!" }]}
        >
          <Input.Password placeholder="Password" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" block>
            Zaloguj
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}
