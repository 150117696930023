import React from "react";
import "./OpeningSection.scss";
import Text from "../../../components/Text/Text";

const OpeningSection = () => {
  return (
    <div className="categoriesOpening">
      <div className="image-container">
        <img src="../images/categories.png" alt="Contact Details" />
      </div>
      <div className="text-container">
        <Text
          title="PIERŚCIONKI"
          text={
            "Pierścionki, które symbolizują miłość i wyjątkowe chwile. Wykonane z" +
            "\n" +
            " najwyższej jakości materiałów, urzekają pięknem i subtelnością."
          }
          showButton={false}
          align="text-left"
          content=""
          path=""
        />
      </div>
    </div>
  );
};

export default OpeningSection;
