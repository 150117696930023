import React, { useState } from "react";
import { Drawer, Button } from "antd";
import { MenuOutlined, SearchOutlined } from "@ant-design/icons";
import { Menu } from "antd";
import "./MobileNav.scss";

const items = [
  {
    key: "1",
    label: "O NAS",
  },
  {
    key: "2",
    label: "NOWOŚCI",
  },
  {
    key: "3",
    label: "PRODUKTY",
    children: [
      { key: "3.1", label: "NASZYJNIKI" },
      { key: "3.2", label: "BRANSOLETKI" },
      { key: "3.3", label: "PIERŚCIONKI" },
      { key: "3.4", label: "KOLCZYKI" },
      { key: "3.5", label: "ZEGARKI" },
      { key: "3.6", label: "BROSZKI" },
      { key: "3.7", label: "BRYLANTY" },
    ],
  },
  {
    key: "4",
    label: "KOLEKCJE",
    children: [
      { key: "4.1", label: "HIGH JEWELS" },
      { key: "4.2", label: "INVISIBLE" },
      { key: "4.3", label: "ZARĘCZYNY" },
      { key: "4.4", label: "ANTYCZNE" },
    ],
  },
  {
    key: "5",
    label: "PRODUKTY NA ZAMÓWIENIE",
  },
  {
    key: "6",
    label: "BESTSELLERY",
  },
  {
    key: "7",
    label: "DLA NIEGO",
    children: [
      { key: "7.1", label: "BRANSOLETKI" },
      { key: "7.2", label: "SYGNETY" },
      { key: "7.3", label: "ŁAŃCUSZKI" },
      { key: "7.4", label: "BRELOKI" },
    ],
  },
  {
    key: "8",
    label: "O DIAMENTACH",
  },
];

export default function MobileNav() {
  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const onClick = (e: any) => {
    console.log("click ", e);
  };

  return (
    <div>
      <Button
        type="text"
        icon={<MenuOutlined />}
        onClick={showDrawer}
        style={{ color: "white" }}
      />
      <Drawer
        title="Menu"
        placement="left"
        closable={true}
        onClose={onClose}
        visible={visible}
        className="mobileNav"
      >
        <Menu onClick={onClick} mode="inline" items={items} />
      </Drawer>
    </div>
  );
}
