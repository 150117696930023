import ChosenForYou from "../../components/Checkout/ChosenForYou/ChosenForYou";
import ShippingDetails from "../../components/Checkout/ShippingDetails/ShippingDetails";
import YourOrder from "../../components/Checkout/YourOrder/YourOrder";
import Page from "../../components/Page/Page";
import AdjustableButton from "../../components/AdjustableButton/AdjustableButton";
import PaymentMethods from "../../components/Cart/PaymentMethods/PaymentMethods";
import "./CartCheckout.scss";
export default function CartCheckout() {
  return (
    <Page>
        <div className="heading">
      <h1 className="CheckoutHeading">PODSUMOWANIE ZAMÓWIENIA</h1>
      <hr  className="CheckoutHR"/>
      <AdjustableButton text={"WRÓĆ DO KOSZYKA"} href={"/cart"} textColor="black" borderColor="black" backgroundColor="white" hoverBackgroundColor="black" hoverTextColor="white" hoverBorderColor="white" />
      </div>
      <div className="CartCheckout">
        <ShippingDetails />
        <YourOrder />
      </div>
      <ChosenForYou />
<PaymentMethods />
    </Page>
  );
}
