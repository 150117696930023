import React, { useState } from "react";
import "./ItemCardHover.scss";
import AdjustableButton from "../AdjustableButton/AdjustableButton";
import { ReactComponent as HeartIcon } from "../../icons/heart.svg";

interface ItemCardHoverProps {
  imageSrc: string;
  title: string;
  price: string;
  content: string;
  path: string;
}

const ItemCardHover = ({
  imageSrc,
  title,
  price,
  content,
  path,
}: ItemCardHoverProps) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      className={`itemCard-hover ${isHovered ? "hovered" : ""}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <img src={imageSrc} alt="Product" className="itemCardImage-hover" />
      <HeartIcon className="icon itemHeartIcon" fill="transparent" />
      <h1>{title}</h1>
      <p>{price}</p>
      {isHovered && (
        <div className="adjustable-button-container">
          <AdjustableButton
            text={content}
            backgroundColor="black"
            textColor="white"
            borderColor="white"
            hoverBackgroundColor="white"
            hoverTextColor="black"
            hoverBorderColor="white"
            href={path}
          />
        </div>
      )}
    </div>
  );
};

export default ItemCardHover;
