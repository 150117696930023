import "./ShippingDetails.scss";
import AdjustableButton from "../../AdjustableButton/AdjustableButton";
export default function ShippingDetails() {
  return (
    <div className="ShippingDetailsContent">
      <div className="first">
      <h1>1. Dane do wysyłki</h1>
      <input type="text" placeholder="Imię i nazwisko*" />
      <input type="text" placeholder="Nazwa firmy" />
      <input type="text" placeholder="Numer telefonu*" />
      <input type="text" placeholder="E-mail*" />
      <input type="text" placeholder="Ulica*"  className="street"/>
      <input type="text" placeholder="Nr budynku / lokalu*" className="nr" />
      <div className="zip-code">
        <input type="text" placeholder="Kod Pocztowy*" />
        <input type="text" placeholder="Miasto*" />
      </div>
      <div className="invoice">
        <input type="checkbox" />
        <p>Podaj dane do faktury</p>
      </div>
      <div className="note">
        <input type="checkbox" />
        <p>Notatka do zamówienia</p>
      </div>
      <input type="text-field" className="note-text" />
      </div>
      <div className="second">
        <h1>2. Wybierz opcję dostawy</h1>
        <div className="option">
          <div className="checkbox-description">
            <input type="checkbox" />
            <div className="innerOption">
              <p>Rezerwacja w salonie</p>
              <p className="description">
                (Odbiór w jednym z salonów M&M Gold)
              </p>
            </div>
          </div>
          <p>BEZPŁATNIE</p>
        </div>
        <div className="option">
          <div className="checkbox-description">
            <input type="checkbox" />
            <div className="innerOption">
              <p>Szybka przesyłka kurierska</p>
              <p className="description">(Wysyłka nawet w 24h)</p>
            </div>
          </div>
          <p>BEZPŁATNIE</p>
        </div>
        <div className="option">
          <div className="checkbox-description">
            <input type="checkbox" />
            <div className="innerOption">
              <p>Dostawa Luxury Concierage</p>
              <p className="description">
                (Dostawa od 4 do 24h na terenie całej Polski, bezpłatna przy
                zamówieniach powyżej 25 000 PLN)
              </p>
            </div>
          </div>
          <p>BEZPŁATNIE</p>
        </div>
      </div>
      <div className="third">
        <h1>3. Wybierz metodę płatności</h1>
        <div className="third-option">
            <input type="checkbox" />
            <p>Przelew na konto</p>
        </div>
        <div className="third-option">
            <input type="checkbox" />
            <p>Płatność przy odbiorze</p>
        </div>
        <div className="third-option">
            <input type="checkbox" />
            <p>Zapoznałam/em się z treścią Regulaminu oraz Polityką prywatności i akceptuję ich treść*</p>
        </div>
      </div>
      {/* <AdjustableButton text={"Kupuję i płacę"} href={"#"} /> */}
    </div>
  );
}
