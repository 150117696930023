import React from "react";
import "./IdeasForGifts.scss";
import Text from "../Text/Text";
import ItemCard from "../ItemCard/ItemCard";

export default function IdeasForGifts() {
  return (
    <div className="ideas-content">
      <div className="text">
        <Text
          title={"POMYSŁY NA PREZENT"}
          text={
            "Chcesz podarować bliskiej osobie wyjątkowy prezent? Z M&M Gold znajdziesz to, czego szukasz! Nasze propozycje prezentów są różnorodne, oryginalne oraz dopasowane do różnych gustów."
          }
          showButton={false}
          align={"text-left"}
          content={""}
          path={""}
        ></Text>
      </div>
      <div className="items">
        <div className="items-row">
          <ItemCard
            imageSrc={"./images/KolczykiZBrylantem.jpeg"}
            title={"KOLCZYKI Z RÓŻOWEGO ZŁOTA ONELOVE2"}
            price={"37 750 PLN"}
            content={"Kup Teraz"}
            path={"#"}
            showButton={true}
          ></ItemCard>
          <ItemCard
            imageSrc={"./images/KolczykiInvisible.jpeg"}
            title={"KOLCZYKI INVISIBLE™ 0.40CT"}
            price={"12 700 PLN"}
            content={"Kup Teraz"}
            path={"#"}
            showButton={true}
          ></ItemCard>
        </div>
        <div className="items-row">
          <ItemCard
            imageSrc={"./images/KolczykiCarskaRosja.jpeg"}
            title={"KOLCZYKI Z DIAMENTAMI 5,30CT CARSKA ROSJA"}
            price={"21 500 PLN"}
            content={"Kup Teraz"}
            path={"#"}
            showButton={true}
          ></ItemCard>
          <ItemCard
            imageSrc={"./images/PierscionekZBrylantem.jpeg"}
            title={"pierścionek zaręczynowyz brylantem"}
            price={"21 500PLN"}
            content={"Kup Teraz"}
            path={"#"}
            showButton={true}
          ></ItemCard>
        </div>
      </div>
    </div>
  );
}
