import "./Text.scss";
import UnderlinedButton from "../UnderlinedButton/UnderlinedButton";
import AdjustableButton from "../AdjustableButton/AdjustableButton";
import React from "react";

export default function Text(props: {
  title: string;
  text: string;
  showButton: boolean;
  align: string;
  content: string;
  path: string;
  adjustableButton?: boolean;
}) {
  const { title, text, showButton, align, content, path, adjustableButton } =
    props;

  const formattedText = text.split("\n").map((str, index) => (
    <React.Fragment key={index}>
      {str}
      <br />
    </React.Fragment>
  ));

  return (
    <div className={`text-component ${align}`}>
      <h1>{title}</h1>
      <p>{formattedText}</p>
      {showButton &&
        (adjustableButton ? (
          <AdjustableButton
            text={content}
            href={path}
            backgroundColor="transparent"
            textColor="#ffffff"
            borderColor="#ffffff"
            hoverBackgroundColor="#ffffff"
            hoverTextColor="#000000"
            hoverBorderColor="#00000"
          />
        ) : (
          <UnderlinedButton content={content} path={path} />
        ))}
    </div>
  );
}
