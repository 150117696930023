import "./GiftIdea.scss";

import Text from "../../Text/Text";
export default function GiftIdea() {
  return (
<div className="GiftIdea">
    <div className="GiftIdeaText">
        <Text title={"pomysł na prezent"} text={"Spraw radość innym wyjątkowym prezentem, który zachwyca blaskiem i elegancją."} showButton={false} align={"text-left"} content={""} path={"#"} />
    </div>
    <div className="GiftIdeaImage">
        <img src="./images/Gift-Idea.jpeg" alt="" />
    </div>

</div>
  );
}
