import "./Sort.scss";
import React, { useState } from "react";
import {
  Menu,
  Dropdown,
  Typography,
  Divider,
  Checkbox,
  MenuProps,
  DropdownProps,
  Button,
  Slider,
  Input,
} from "antd";
import { DownOutlined } from "@ant-design/icons";

const { Text } = Typography;

export default function Sort() {
  const [openSort, setOpenSort] = useState(false);
  const [openCollection, setOpenCollection] = useState(false);
  const [openCategory, setOpenCategory] = useState(false);
  const [openColor, setOpenCololr] = useState(false);
  const [openStone, setOpenStone] = useState(false);
  const [selectedKey, setSelectedKey] = useState<string | null>(null);
  const [priceRange, setPriceRange] = useState<number[]>([100, 10000]);

  const handlePriceChange = (value: number[]) => {
    setPriceRange(value);
  };

  const handleSave = () => {
    console.log("Zakres cen zapisany:", priceRange);
  };

  const handleCheckboxChange = (key: string) => {
    if (key == selectedKey) {
      setSelectedKey(null);
    } else {
      setSelectedKey(key);
    }
  };

  const handleMenuClick: MenuProps["onClick"] = (e) => {
    // if (e.key === "3") {
    //   setOpen(false);
    // }
  };

  const handleOpenChange = (key: string) => {
    return (nextOpen: boolean, info: { source: string }) => {
      if (info.source === "trigger" || nextOpen) {
        switch (key) {
          case "sort":
            setOpenSort(nextOpen);
            break;
          case "collection":
            setOpenCollection(nextOpen);
            break;
          case "category":
            setOpenCategory(nextOpen);
            break;
          case "color":
            setOpenCololr(nextOpen);
            break;
          case "stone":
            setOpenStone(nextOpen);
            break;
          default:
            break;
        }
      }
    };
  };

  const handleInputChange = (value: number, index: number) => {
    const updatedRange = [...priceRange];
    updatedRange[index] = value;
    setPriceRange(updatedRange);
  };

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <Checkbox
          checked={selectedKey === "1"}
          onClick={(e) => {
            e.stopPropagation(); // Zapobiega zamykaniu menu
            handleCheckboxChange("1");
          }}
        >
          NAJNOWSZE
        </Checkbox>
      ),
    },
    {
      key: "2",
      label: (
        <Checkbox
          checked={selectedKey === "2"}
          onClick={(e) => {
            e.stopPropagation();
            handleCheckboxChange("2");
          }}
        >
          CENA OD NAJNIŻSZEJ
        </Checkbox>
      ),
    },
    {
      key: "3",
      label: (
        <Checkbox
          checked={selectedKey === "3"}
          onClick={(e) => {
            e.stopPropagation();
            handleCheckboxChange("3");
          }}
        >
          CENA OD NAJWYŻSZEJ
        </Checkbox>
      ),
    },
  ];

  const collectionItems: MenuProps["items"] = [
    {
      key: "1",
      label: <Checkbox>KOLEKCJA 1</Checkbox>,
    },
    {
      key: "2",
      label: <Checkbox>KOLEKCJA 2</Checkbox>,
    },
    {
      key: "3",
      label: <Checkbox>KOLEKCJA 3</Checkbox>,
    },
  ];

  const colorItems: MenuProps["items"] = [
    {
      key: "1",
      label: <Checkbox>Złoty</Checkbox>,
    },
    {
      key: "2",
      label: <Checkbox>Srebrny</Checkbox>,
    },
    {
      key: "3",
      label: <Checkbox>Biały</Checkbox>,
    },
  ];

  const categoryItems: MenuProps["items"] = [
    {
      key: "1",
      label: <Checkbox>Pierścionki</Checkbox>,
    },
    {
      key: "2",
      label: <Checkbox>Kolczyki</Checkbox>,
    },
    {
      key: "3",
      label: <Checkbox>Naszyjniki</Checkbox>,
    },
  ];

  const stoneItems: MenuProps["items"] = [
    {
      key: "1",
      label: <Checkbox>KAMIEN 1</Checkbox>,
    },
    {
      key: "2",
      label: <Checkbox>KAMIEN 2</Checkbox>,
    },
    {
      key: "3",
      label: <Checkbox>KAMIEN 3</Checkbox>,
    },
  ];

  const dropdownContent = (
    <div className="dropdownPrice" style={{ padding: "16px", width: "250px" }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Input
          type="number"
          min={0}
          max={priceRange[1]}
          value={priceRange[0]}
          onChange={(e) => handleInputChange(Number(e.target.value), 0)}
          style={{ width: "120px", textAlign: "center" }}
        />
        <Text style={{ alignSelf: "center", margin: "0 8px" }}>-</Text>
        <Input
          type="number"
          min={priceRange[0]}
          max={10000}
          value={priceRange[1]}
          onChange={(e) => handleInputChange(Number(e.target.value), 1)}
          style={{ width: "120px", textAlign: "center" }}
        />
      </div>
      <Slider
        range
        min={100}
        max={10000}
        step={100}
        defaultValue={priceRange}
        onChange={handlePriceChange}
        style={{ margin: "16px 0" }}
        tooltip={{ formatter: null }}
      />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Text>100 zł</Text>
        <Text>10000 zł</Text>
      </div>
      {/* Przycisk zapisz */}
      <Button
        type="primary"
        onClick={handleSave}
        block
        style={{
          marginTop: "16px",
          backgroundColor: "black",
          color: "white",
          border: "none",
        }}
      >
        ZAPISZ
      </Button>
    </div>
  );

  return (
    <div className="sortBarContainer">
      <div className="sortBar">
        <div className="sortBarElements">
          <Dropdown
            menu={{ items, onClick: handleMenuClick }}
            trigger={["click"]}
            overlayClassName="dropdown"
            placement="bottomLeft"
            open={openSort}
            onOpenChange={handleOpenChange("sort")}
          >
            <Text style={{ cursor: "pointer" }}>
              SORTUJ WEDŁUG <DownOutlined />
            </Text>
          </Dropdown>
          <Divider
            type="vertical"
            style={{ color: "black", borderInlineStart: "1px soild black" }}
          />
          <Dropdown
            // menu={menu}
            trigger={["click"]}
            overlayClassName="dropdown"
            overlay={dropdownContent}
            placement="bottomLeft"
          >
            <Text style={{ cursor: "pointer" }}>
              CENA <DownOutlined />
            </Text>
          </Dropdown>
          <Dropdown
            menu={{ items: collectionItems, onClick: handleMenuClick }}
            trigger={["click"]}
            overlayClassName="dropdown"
            placement="bottomLeft"
            open={openCollection}
            onOpenChange={handleOpenChange("collection")}
          >
            <Text style={{ cursor: "pointer" }}>
              KOLEKCJA <DownOutlined />
            </Text>
          </Dropdown>

          <Dropdown
            menu={{ items: categoryItems, onClick: handleMenuClick }}
            trigger={["click"]}
            overlayClassName="dropdown"
            placement="bottomLeft"
            open={openCategory}
            onOpenChange={handleOpenChange("category")}
          >
            <Text style={{ cursor: "pointer" }}>
              KATEGORIA <DownOutlined />
            </Text>
          </Dropdown>
          <Dropdown
            menu={{ items: colorItems, onClick: handleMenuClick }}
            trigger={["click"]}
            overlayClassName="dropdown"
            placement="bottomLeft"
            open={openColor}
            onOpenChange={handleOpenChange("color")}
          >
            <Text style={{ cursor: "pointer" }}>
              KOLOR KRUSZCA <DownOutlined />
            </Text>
          </Dropdown>

          <Dropdown
            menu={{ items: stoneItems, onClick: handleMenuClick }}
            trigger={["click"]}
            overlayClassName="dropdown"
            placement="bottomLeft"
            open={openStone}
            onOpenChange={handleOpenChange("stone")}
          >
            <Text style={{ cursor: "pointer" }}>
              KAMIEŃ GŁÓWNY <DownOutlined />
            </Text>
          </Dropdown>
        </div>

        {/* <Text style={{ fontSize: "14px", color: "#666" }}>287 PRODUKTÓW</Text> */}
      </div>
    </div>
  );
}
