import React, { useState, useEffect, useRef } from "react";
import "./RecentlySeen.scss";
import Text from "../Text/Text";
import AdjustableButton from "../AdjustableButton/AdjustableButton";
import {
  CustomLeftArrow,
  CustomRightArrow,
} from "../CustomArrows/CustomArrows";

const carousel = [
  { imageSrc: "/images/Recently1.jpeg", text: "BRANSOLETKA INVISIBLE™" },
  { imageSrc: "/images/Recently2.jpeg", text: "Rolex DATEJUST 36" },
  { imageSrc: "/images/Recently1.jpeg", text: "LUKSUSOWA KOLIA Z BRYLANTAMI" },
];

const images = [
  { imageSrc: "/images/Recently1.jpeg", text: "LUKSUSOWA KOLIA Z BRYLANTAMI" },
  {
    imageSrc: "/images/Recently2.jpeg",
    text: "PIERŚCIONEK Tiffany One Setting",
  },
  { imageSrc: "/images/Recently1.jpeg", text: "ZEGAREK ROLEX DATEJUST 36" },
];

const RecentlyImages = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [itemWidth, setItemWidth] = useState(500);
  const gap = 15;
  const itemsRef = useRef<HTMLDivElement>(null);
  const carouselRef = useRef<HTMLDivElement>(null);

  const [itemsToShow, setItemsToShow] = useState(0);

  useEffect(() => {
    const updateItemsToShow = () => {
      if (carouselRef.current) {
        const carouselWidth = carouselRef.current.offsetWidth;
        const newItemsToShow = Math.floor(carouselWidth / (itemWidth + gap));
        setItemsToShow(newItemsToShow);
        setCurrentIndex(0);
      }
    };

    updateItemsToShow();

    window.addEventListener("resize", updateItemsToShow);

    return () => {
      window.removeEventListener("resize", updateItemsToShow);
    };
  }, [itemWidth, gap]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 500) {
        setItemWidth(390);
      } else {
        setItemWidth(500);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (itemsRef.current) {
      itemsRef.current.style.transition = "transform 0.5s ease-in-out";
    }
  }, [currentIndex]);

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) =>
      Math.min(prevIndex + 1, carousel.length - itemsToShow - 1)
    );
  };

  const showLeftArrow = currentIndex > 0;
  const showRightArrow = currentIndex < carousel.length - itemsToShow - 1;

  return (
    <div className="recently-content">
      <div className="text-mobile">
        <Text
          title={"OSTATNIO OGLĄDANE"}
          text={
            "Podczas ostatiej wizyty na naszej stronie zainteresowało Cię kilka produktów. Może chcesz do nich wrócić?"
          }
          showButton={false}
          align={"text-center"}
          content={""}
          path={""}
        />
        <AdjustableButton
          text={"Zobacz Więcej"}
          href={"#"}
          backgroundColor="white"
          textColor="black"
          borderColor="black"
          hoverBackgroundColor="black"
          hoverTextColor="white"
          hoverBorderColor="white"
        />
      </div>
      <div className="recently-carousel" ref={carouselRef}>
        {showLeftArrow && <CustomLeftArrow onClick={handlePrevClick} />}
        <div
          className="carousel-inner"
          style={{
            transform: `translateX(-${currentIndex * (itemWidth + gap)}px)`,
          }}
          ref={itemsRef}
        >
          {carousel.map((item, index) => (
            <div key={index} className="carousel-item">
              <img src={item.imageSrc} alt={item.text} />
              <p>{item.text}</p>
            </div>
          ))}
        </div>
        {showRightArrow && <CustomRightArrow onClick={handleNextClick} />}
      </div>

      <div className="recently-text-images">
        <div className="recently-text">
          <Text
            title={"OSTATNIO OGLĄDANE"}
            text={
              "Podczas ostatiej wizyty na naszej stronie zainteresowało Cię kilka produktów. Może chcesz do nich wrócić?"
            }
            showButton={false}
            align={"text-left"}
            content={""}
            path={""}
          />
          <AdjustableButton
            text={"Zobacz Więcej"}
            href={"#"}
            backgroundColor="white"
            textColor="black"
            borderColor="black"
            hoverBackgroundColor="black"
            hoverTextColor="white"
            hoverBorderColor="white"
          />
        </div>
        <div className="recently-images">
          {images.map((image, index) => (
            <div key={index} className="image-container">
              <img src={image.imageSrc} alt={image.text} />
              <p>{image.text}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RecentlyImages;
