import React from "react";
import "./InvisibleCollection.scss";
import Text from "../Text/Text";
import ItemCardHover from "../ItemCardHover/ItemCardHover";
export default function InvisibleCollection() {
  return (
    <div className="invisible-content">
      <div className="invisible-heading">
        <Text
          title={"INVSIBLE COLLECTION™"}
          text={
            "M&M GOLD INVISIBLE COLLECTION™ to unikatowa biżuteria marki. Nowa jakość na Polskim rynku i najbardziej wyszukany prezent jaki może otrzymać kobieta. Piękna biżuteria powinna być niepowtarzalna. Właśnie taka jest ta tworzona przez naszych artystów."
          }
          showButton={true}
          align={"text-center"}
          content={"Zobacz całą kolekcję"}
          path={"#"}
        />
      </div>
      <div className="invisible-cards">
        <ItemCardHover
          imageSrc={"/images/Invisible-pierscionek.jpeg"}
          title={"PIERŚCIONEK UNSTOPPABLE"}
          price={"39 500 PLN"}
          content={"Zobacz szczegóły"}
          path={"#"}
        />
        <ItemCardHover
          imageSrc={"/images/Invisible-zlota-kolia.jpeg"}
          title={"ZŁOTA KOLIA Z BRYLANTAMI"}
          price={"39 500 PLN"}
          content={"Zobacz szczegóły"}
          path={"#"}
        />
        <ItemCardHover
          imageSrc={"/images/Invisible-kolczyki-artystyczne.jpeg"}
          title={"ARTYSTYCZNE KOLCZYKI"}
          price={"39 500 PLN"}
          content={"Zobacz szczegóły"}
          path={"#"}
        />
        <ItemCardHover
          imageSrc={"/images/Invisible-kolczyki.jpeg"}
          title={'KOLCZYKI "INVISIBLEEXCEPTIONNEL™"'}
          price={"39 500 PLN"}
          content={"Zobacz szczegóły"}
          path={"#"}
        />
      </div>
    </div>
  );
}
