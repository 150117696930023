import React from "react";
import Page from "../../components/Page/Page";
import './Regulations.scss';
import RegulationsHeader from "../../components/Regulations/RegulationsHeader/RegulationsHeader";
import RegulationsText from "../../components/Regulations/RegulationsText/RegulationsText";
export default function Regulations() {
    return (
        <Page>
            <RegulationsHeader />
            <div className="RegulationsText">
                <RegulationsText />
            </div>
        </Page>
    );
}