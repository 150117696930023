import Page from "../../components/Page/Page";
import "./Gifts.scss";
import MothersDay from "../../components/Gifts/MothersDay/MothersDay";
import GiftIdea from "../../components/Gifts/GiftIdea/GiftIdea";
import Engagement from "../../components/Gifts/Engagement/Engagement";
import ImageTextSection from "../../components/Gifts/ImageTextSection/ImageTextSection";
import ItemCardRow from "../../components/Gifts/ItemCardColorRow/ItemCardColorRow";

const zegarki = [
  {
    imageSrc: "/images/FollowUs4.png",
    title: "Product 1",
    price: "99.99 PLN",
    colors: ["gold", "silver"],
    path: "#",
  },
  {
    imageSrc: "/images/FollowUs3.png",
    title: "Product 2",
    price: "149.99 PLN",
    colors: ["pink"],
    path: "#",
  },
  {
    imageSrc: "/images/FollowUs1.png",
    title: "Product 3",
    price: "199.99 PLN",
    colors: ["pink", "gold", "silver"],
    path: "#",
  },
  {
    imageSrc: "/images/FollowUs2.png",
    title: "Product 4",
    price: "249.99 PLN",
    colors: ["pink", "gold", "silver"],
    path: "#",
  },
];
const bransoletki = [
  {
    imageSrc: "/images/Category-Bransoletki.jpeg",
    title: "Product 1",
    price: "99.99 PLN",
    colors: ["gold", "silver"],
    path: "#",
  },
  {
    imageSrc: "/images/Category-Zegarki.png",
    title: "Product 2",
    price: "149.99 PLN",
    colors: ["pink"],
    path: "#",
  },
  {
    imageSrc: "/images/Category-Kolczyki.jpeg",
    title: "Product 3",
    price: "199.99 PLN",
    colors: ["pink", "gold", "silver"],
    path: "#",
  },
  {
    imageSrc: "/images/Category-Naszyjniki.jpeg",
    title: "Product 4",
    price: "249.99 PLN",
    colors: ["pink", "gold", "silver"],
    path: "#",
  },
];
const kolczyki = [
  {
    imageSrc: "/images/Category-Bransoletki.jpeg",
    title: "Product 1",
    price: "99.99 PLN",
    colors: ["gold", "silver"],
    path: "#",
  },
  {
    imageSrc: "/images/Category-Zegarki.png",
    title: "Product 2",
    price: "149.99 PLN",
    colors: ["pink"],
    path: "#",
  },
  {
    imageSrc: "/images/Category-Kolczyki.jpeg",
    title: "Product 3",
    price: "199.99 PLN",
    colors: ["pink", "gold", "silver"],
    path: "#",
  },
  {
    imageSrc: "/images/Category-Naszyjniki.jpeg",
    title: "Product 4",
    price: "249.99 PLN",
    colors: ["pink", "gold", "silver"],
    path: "#",
  },
];
const pierscionki = [
  {
    imageSrc: "/images/Category-Bransoletki.jpeg",
    title: "Product 1",
    price: "99.99 PLN",
    colors: ["gold", "silver"],
    path: "#",
  },
  {
    imageSrc: "/images/Category-Zegarki.png",
    title: "Product 2",
    price: "149.99 PLN",
    colors: ["pink"],
    path: "#",
  },
  {
    imageSrc: "/images/Category-Kolczyki.jpeg",
    title: "Product 3",
    price: "199.99 PLN",
    colors: ["pink", "gold", "silver"],
    path: "#",
  },
  {
    imageSrc: "/images/Category-Naszyjniki.jpeg",
    title: "Product 4",
    price: "249.99 PLN",
    colors: ["pink", "gold", "silver"],
    path: "#",
  },
];

export default function Gifts() {
  return (
    <Page>
      <GiftIdea />
      <MothersDay />
      <ImageTextSection
        imgPath="./images/FollowUs5.png"
        title="ZEGARKI"
        text="Zegarki, które zatrzymują chwilę - idealny prezent na każdą okazję. Wyrafinowane i luksusowe, stanowią doskonały wybór dla kogoś wyjątkowego."
        path="#"
        content="Zobacz całą kolekcję"
      />
      <ItemCardRow products={zegarki} />
      <ImageTextSection
        imgPath="./images/FollowUs4.png"
        title="BRANSOLETKI"
        text="Podaruj niezapomniane chwile dzięki naszym bransoletkom. Eleganckie i unikalne, idealne na każdą okazję. Wykonane z najwyższej jakości materiałów, zapewniają ponadczasowy blask i wyjątkowy design. Doskonały prezent dla kogoś bliskiego lub wyjątkowy dodatek do Twojej własnej kolekcji."
        path="#"
        content="Zobacz całą kolekcję"
        variant="reverse"
      />
      <ItemCardRow products={bransoletki} />
      <ImageTextSection
        imgPath="./images/FollowUs3.png"
        title="Kolczyki"
        text="Kolczyki, które olśniewają swoim blaskiem i wyjątkowym stylem. Stworzone z myślą o subtelnej elegancji, dodają wyrafinowania każdej kreacji. Wybierz dla siebie lub podaruj jako prezent, aby uczcić ważne chwile."
        path="#"
        content="Zobacz całą kolekcję"
      />
      <ItemCardRow products={kolczyki} />
      <ImageTextSection
        imgPath="./images/FollowUs2.png"
        title="PIERŚCIONKI"
        text="Pierścionki, które symbolizują miłość i wyjątkowe chwile. Wykonane z najwyższej jakości materiałów, urzekają pięknem i subtelnością. Wybierz idealny prezent, który będzie towarzyszył przez lata – wyjątkowy dodatek, który podkreśli niezapomniane momenty."
        path="#"
        content="Zobacz całą kolekcję"
        variant="reverse"
      />
      <ItemCardRow products={pierscionki} />
      <Engagement />
    </Page>
  );
}
