import { IStorage } from "../types/IStorage";

export const useStorage = () => {

    const create = (object: Storage): IStorage => ({
        get: (key: string) => {
            try {
              return object.getItem(key);
            } catch (error) {
              console.error(`Error getting item from storage: ${error}`);
              return null;
            }
        },
        set: (key: string, value: string) => {
            try {
                object.setItem(key, value);
            } catch (error) {
                console.error(`Error setting item in storage: ${error}`);
            }
        },
        remove: (key: string) => {
            try {
                object.removeItem(key);
            } catch (error) {
                console.error(`Error removing item from storage: ${error}`);
            }
        },
        clear: () => {
            try {
                object.clear();
            } catch (error) {
                console.error(`Error clearing storage: ${error}`);
            }
        },
        getJSON: <T>(key: string): T | null => {
            try {
                const item = object.getItem(key);
                return item ? JSON.parse(item) : null;
            } catch (error) {
              console.error(`Error parsing JSON from storage: ${error}`);
              return null;
            }
          },
        setJSON: (key: string, value: any) => {
            try {
                object.setItem(key, JSON.stringify(value));
            } catch (error) {
                console.error(`Error setting JSON in storage: ${error}`);
            }
        },
    });
    
    const storage = {
        local: create(localStorage),
        session: create(sessionStorage),
    };

    return { storage };
};