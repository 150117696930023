import React from 'react';
import './DepartmentItem.scss';
import IconTextGradient from '../../../IconTextGradient/IconTextGradient';

interface DepartmentItemProps {
    title: string;
    phone: string;
}

const DepartmentItem: React.FC<DepartmentItemProps> = ({ title, phone }) => {
    return (
        <div className="department-item">
            <div className="department-title">{title}</div>
            <IconTextGradient icon="phone" text={phone} />
        </div>
    );
};

export default DepartmentItem;
