import "./CategorySection.scss";
import CategoryCard from "../CategoryCard/CategoryCard";

export default function CategorySection() {
  return (
    <div className="main-container">
      <div className="row-container">
        <CategoryCard
          variant={"narrow"}
          imgPath={"/images/Category-Zegarki.png"}
          title={"Zegarki"}
          buttonText={"Poznaj ofertę"}
          buttonLink={"#"}
        />
        <CategoryCard
          variant={"narrow"}
          imgPath={"/images/Category-Naszyjniki.jpeg"}
          title={"Naszyjniki"}
          buttonText={"Poznaj ofertę"}
          buttonLink={"#"}
        />
        <CategoryCard
          variant={"narrow"}
          imgPath={"/images/Category-Kolczyki.jpeg"}
          title={"Kolczyki"}
          buttonText={"Poznaj ofertę"}
          buttonLink={"#"}
        />
      </div>
      <div className="row-container">
        <CategoryCard
          variant={"wide"}
          imgPath={"/images/Category-Bransoletki.jpeg"}
          title={"Bransoletki"}
          buttonText={"Poznaj ofertę"}
          buttonLink={"#"}
        />
        <CategoryCard
          variant={"wide"}
          imgPath={"/images/Category-Pierscionki.jpeg"}
          title={"Pierścionki"}
          buttonText={"Poznaj ofertę"}
          buttonLink={"#"}
        />
      </div>
    </div>
  );
}
