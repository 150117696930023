import InfoSection from "../../components/OpeningSection/InfoSection";
import Page from "../../components/Page/Page";
import MatchingProducts from "./MatchingProducts/MatchingProducts";
import "./Product.scss";
import ProductDescription from "./ProductDescription/ProductDescription";
import ProductDetails from "./ProductDetails/ProductDetails";

export default function Product() {
  return (
    <Page>
      <ProductDetails />
      <ProductDescription />
      <div className="dlaczego_section">
        <p>DLACZEGO M&M GOLD?</p>
        <InfoSection />
      </div>
      <MatchingProducts />
    </Page>
  );
}
