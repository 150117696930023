import React from "react";
import Page from "../../components/Page/Page";
import OpeningSection from "../../components/AboutUs/OpeningSection/OpeningSection";
import Products from "../../components/AboutUs/Products/Products";
import JewelryStar from "../../components/AboutUs/JewelryStar/JewelryStar";
import Details from "../../components/AboutUs/Details/Details";
import OurPlacesSectionTabs from "../../components/OurPlacesSection/OurPlacesSectionTabs";
import ShowroomItem from "../../components/Showrooms/ShowroomItem/ShowroomItem";

export default function AboutUs() {
  return (
    <Page>
      <OpeningSection />
      <Products />
      <JewelryStar />
      <Details />
      <OurPlacesSectionTabs />
      <ShowroomItem
        place={"GALERIA PÓŁNOCNA"}
        address={"ul. Światowida 17, 03-144 Warszawa"}
        phone_number={"+48 515 015 715"}
        email={""}
        path={"#"}
        hours_week={"11:00 - 21:00"}
        hours_weekend={"11:00 - 19:00"}
        ImgPath={"./images/showroom1.jpeg"}
        vis_path={"#"}
        reverse
      />
      <ShowroomItem
        place={"CH KLIF"}
        address={"ul.Okopowa 58/72, 01-042 Warszawa"}
        phone_number={"+48 XXX XXX XXX"}
        email={""}
        path={"#"}
        hours_week={"11:00 - 21:00"}
        hours_weekend={"11:00 - 19:00"}
        ImgPath={"./images/showroom2.jpeg"}
        vis_path={"#"}
        reverse
      />
      <ShowroomItem
        place={"CH MODO"}
        address={"ul. Łopuszańska 23, 02-220 Warszawa"}
        phone_number={"+48 787 787 777"}
        email={""}
        path={"#"}
        hours_week={"11:00 - 21:00"}
        hours_weekend={"11:00 - 19:00"}
        ImgPath={"./images/showroom1.jpeg"}
        vis_path={"#"}
        reverse
      />
      <ShowroomItem
        place={"GALERIA PANORAMA"}
        address={"ul. Witosa 31, 00-710 Warszawa"}
        phone_number={"+48 500 545 550"}
        email={""}
        path={"#"}
        hours_week={"11:00 - 21:00"}
        hours_weekend={"11:00 - 19:00"}
        ImgPath={"./images/showroom3.jpeg"}
        vis_path={""}
        reverse
      />
    </Page>
  );
}
