import React from "react";
import "./CategoryCard.scss";
import AdjustableButton from "../AdjustableButton/AdjustableButton";

interface CategoryCardProps {
  variant: "narrow" | "wide";
  imgPath: string;
  title: string;
  buttonText: string;
  buttonLink: string;
}

const CategoryCard = ({
  variant,
  imgPath,
  title,
  buttonText,
  buttonLink,
}: CategoryCardProps) => {
  return (
    <div className={`card ${variant}`}>
      <img src={imgPath} alt={title} />
      <div className="card-content">
        <h1 className="card-title">{title}</h1>
        <AdjustableButton
          text={buttonText}
          href={buttonLink}
          backgroundColor="#ffffff"
          textColor="#000000"
          borderColor="#000000"
          hoverBackgroundColor="#000000"
          hoverTextColor="#ffffff"
          hoverBorderColor="#000000"
        />
      </div>
    </div>
  );
};

export default CategoryCard;
