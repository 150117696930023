import { useState } from 'react';

const useNavbarVisibility = () => {
    const [isVisible, setVisibility] = useState(false);

    const changeVisibility = () => {
        setVisibility(!isVisible);
    };

    return { isVisible, changeVisibility };
};

export default useNavbarVisibility;
