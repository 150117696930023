import "./Engagement.scss";

import Text from "../../Text/Text";
export default function Engagement() {
  return (
    <div className="Engagement">
      <Text
        title={"ZARĘCZYNY"}
        text={
          "W salonie M&M Gold Warszawa przygotowaliśmy największy wybór abyś mógł wybrać najlepszy pierścionek zaręczynowy, które uwiecznią najważniejszą chwilę w życiu. Dzięki naszym niepowtarzalnym projektom, możesz podarować swojej wybrance pierścionek jedyny w swoim rodzaju, który będzie wyjątkowym świadectwem Twoich uczuć! Zaręczyny są jedną z najważniejszych chwil dla każdej zakochanej pary. Taka niesamowita chwila wymaga odpowiedniej oprawy oraz pięknego pierścionka zaręczynowego, który znajdziecie oczywiście w naszym salonie M&M GOLD."
        }
        showButton={true}
        align={"text-right"}
        content={"Zobacz całą kolekcję"}
        path={"#"}
      />
    </div>
  );
}
