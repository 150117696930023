import { ReactComponent as GiaIcon } from "../../../icons/gia.svg";
import { ReactComponent as IgiIcon } from "../../../icons/igi.svg";
import { ReactComponent as HdaIcon } from "../../../icons/hda.svg";
import "./FooterSponsors.scss";
export default function FooterSponsors() {
  return (
    <div className="FooterSponsors">
      <GiaIcon />
      <HdaIcon />
      <IgiIcon />
    </div>
  );
}
