import React, { useState, useEffect } from "react";
import { Select, Spin } from "antd";
import { useSuperCategory } from "../../../hooks/useSuperCategory";
import { useCategory } from "../../../hooks/useCategory";
import { useCollection } from "../../../hooks/useCollection";
import { useType } from "../../../hooks/useType";

const { Option } = Select;

interface Identifiable {
  id: React.Key;
  name: string;
}

interface ProductFormSelectProps<T extends Identifiable> {
  placeholder: string;
  value: T | undefined;
  onChange: (value: T) => void;
  dataType: "supercategory" | "category" | "collection" | "type";
}

function ProductFormSelect<T extends Identifiable>({
  placeholder,
  value,
  onChange,
  dataType,
}: ProductFormSelectProps<T>) {
  const [data, setData] = useState<T[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const { supercategory } = useSuperCategory();
  const { category } = useCategory();
  const { collection } = useCollection();
  const { type } = useType();

  useEffect(() => {
    setLoading(true);
    let fetchFunction: () => Promise<T[]>;

    switch (dataType) {
      case "supercategory":
        fetchFunction = async () => {
          const response = await supercategory.getAll.execute();
          return response.map((item: any) => item.superCategory);
        };
        break;
      case "category":
        fetchFunction = async () => {
          const response = await category.getAll.execute();
          return response.map((item: any) => item.category);
        };
        break;
      case "collection":
        fetchFunction = async () => {
          const response = await collection.getAll.execute();
          return response.map((item: any) => item.collection);
        };
        break;
      case "type":
        fetchFunction = async () => {
          const response = await type.getAll.execute();
          return response.map((item: any) => item.type);
        };
        break;
      default:
        setError("Nieznany typ danych.");
        setLoading(false);
        return;
    }

    fetchFunction()
      .then((result) => {
        setData(result);
        setLoading(false);
      })
      .catch((err) => {
        setError("Wystąpił błąd podczas ładowania danych.");
        setLoading(false);
      });
  }, []);

  return (
    <Select
      placeholder={placeholder}
      loading={loading}
      value={value ? value.id : undefined}
      onChange={(selectedId) => {
        const selectedItem = data.find((item) => item.id === selectedId);
        if (selectedItem) {
          onChange(selectedItem);
        }
      }}
    >
      {loading ? (
        <Option value="" disabled>
          <Spin size="small" /> Ładowanie...
        </Option>
      ) : error ? (
        <Option value="" disabled>
          {error}
        </Option>
      ) : (
        data.map((item) => (
          <Option key={item.id} value={item.id}>
            {item.name}
          </Option>
        ))
      )}
    </Select>
  );
}

export default ProductFormSelect;
