import "./OurPlacesSectionTabs.scss";
import { Image, Tabs } from "antd";
import OurPlacesSection from "./OurPlacesSection";

type Location = {
  location: string,
  address: string,
  addressExt: string,
  tel: string,
  img_path: string,
  position_x: string,
  position_y: string,
  pin_position_x: string,
  pin_position_y: string,
}

export default function OurPlacesSectionTabs() {
  const warszawa: Location[] = [
    {
      location: "GALERIA PÓŁNOCNA",
      address: "ul. Światowida  17",
      addressExt: "03-144 Warszawa",
      tel: "515 015 717",
      img_path: "images/loc1.png",
      position_x: "45%",
      position_y: "3.3%",
      pin_position_x: "38.2%",
      pin_position_y: "14%",
    },
    {
      location: "CH KLIF",
      address: "ul. Okopowa 58/72",
      addressExt: "03-144 Warszawa",
      tel: "515 015 717",
      img_path: "images/loc1.png",
      position_x: "47%",
      position_y: "32.3%",
      pin_position_x: "41.2%",
      pin_position_y: "44%",
    },
    {
      location: "CH MODO",
      address: "ul. Łopuszańska 22",
      addressExt: "03-144 Warszawa",
      tel: "515 015 717",
      img_path: "images/loc1.png",
      position_x: "8.2%",
      position_y: "53%",
      pin_position_x: "34.2%",
      pin_position_y: "68.5%",
    },
    {
      location: "GALERIA PANORAMA",
      address: "ul. Witosa 31",
      addressExt: "03-144 Warszawa",
      tel: "515 015 717",
      img_path: "images/loc1.png",
      position_x: "65.5%",
      position_y: "67%",
      pin_position_x: "60.8%",
      pin_position_y: "65.6%",
    },
  ];

  const poznan: Location[] = [
    {
      location: "GALERIA STARY BROWAR",
      address: "ul. Półwiejska 42",
      addressExt: "03-144 Warszawa",
      tel: "515 015 717",
      img_path: "images/loc1.png",
      position_x: "47%",
      position_y: "28%",
      pin_position_x: "45%",
      pin_position_y: "44%",
    },
  ];

  const items = [
    {
      key: "1",
      label: "Warszawa",
      children: (
        <OurPlacesSection locations={warszawa} city='Warszawa'/>
      ),
    },
    {
      key: "2",
      label: "Poznań",
      children: (
        <OurPlacesSection locations={poznan} city='Poznan'/>
      ),
    },
  ];

  return (
    <div className="OurPlacesContainer">
      <Tabs
        type="line"
        items={items}
        // onChange={onChange}
        centered
        size="large"
        defaultActiveKey="1"
        className="PlacesTabs"
      />
    </div>
  );
}