import React from "react";
import "./CustomArrows.scss";

interface ArrowProps {
  onClick?: () => void;
}

const CustomLeftArrow: React.FC<ArrowProps> = ({ onClick }) => {
  return (
    <div className="custom-arrow custom-left-arrow" onClick={onClick}>
      &#8249;
    </div>
  );
};

const CustomRightArrow: React.FC<ArrowProps> = ({ onClick }) => {
  return (
    <div className="custom-arrow custom-right-arrow" onClick={onClick}>
      &#8250;
    </div>
  );
};

export { CustomLeftArrow, CustomRightArrow };