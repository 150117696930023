import BankAccount from "../../components/Contact/BankAccount/BankAccount";
import OpeningSection from "../../components/Contact/OpeningSection/OpeningSection";
import Departaments from "../../components/Contact/Departments/Departments";
import Shop from "../../components/Contact/Shop/Shop";
import Page from "../../components/Page/Page";
import OurPlacesSectionTabs from "../../components/OurPlacesSection/OurPlacesSectionTabs";

export default function Contact() {
  return (
    <Page background="white">
      <OpeningSection />
      <Departaments />
      <BankAccount />
      <OurPlacesSectionTabs />
      <Shop />
    </Page>
  );
}
