import React from "react";
import Page from "../../components/Page/Page";
import './Privacy.scss';
import PrivacyHeader from "../../components/PrivacyPolicy/PrivacyHeader/PrivacyHeader";
import PrivacyText from "../../components/PrivacyPolicy/PrivacyText/PrivacyText";
export default function PrivacyPolicy() {
    return (
        <Page>
            <PrivacyHeader />
            <div className="PrivacyPolicyText">
                <PrivacyText />
            </div>
        </Page>
    );
}