import React from "react";
import "./NewProductsSection.scss";
import ItemCard from "../ItemCard/ItemCard";
import Text from "../Text/Text";

const products = [
  {
    imageSrc: "/images/Pierscionek.jpeg",
    title: "PIERŚCIONEK WHITELUXURY™ Z BRYLANTEM 4.40CT",
    price: "430 500 PLN",
    content: "Kup teraz",
    path: "",
    showButton: true,
  },
  {
    imageSrc: "/images/rolexGMTMaster.jpeg",
    title: "ZEGAREK ROLEX GMT MASTER II ROOT BEER",
    price: "20 750 EUR",
    content: "Kup teraz",
    path: "",
    showButton: true,
  },
  {
    imageSrc: "/images/CapsuleCollectionLimited.png",
    title: 'BRANSOLETKA "CAPSULE COLLECTION LIMITED™"',
    price: "24 000 PLN",
    content: "Kup teraz",
    path: "",
    showButton: true,
  },
  {
    imageSrc: "/images/KolczykiInvisible.png",
    title: 'KOLCZYKI Z DIAMENTAMI "INVISIBLE™"',
    price: "9 900 PLN",
    content: "Kup teraz",
    path: "",
    showButton: true,
  },
];

export default function NewProductsSection() {
  return (
    <div className="main-contener">
      <Text
        title="ODKRYJ NASZE NOWOŚCI"
        text="Zanurz się w świecie nowości wśród luksusowej biżuterii."
        align="text-center"
        content=""
        path=""
        showButton={false}
      ></Text>
      <div className="products">
        {products.map((product, index) => (
          <ItemCard
            key={index}
            imageSrc={product.imageSrc}
            title={product.title}
            price={product.price}
            content={product.content}
            path={product.path}
            showButton={product.showButton}
            variant="small"
          />
        ))}
      </div>
    </div>
  );
}
