import { Children } from "../../interfaces/children";
import "./Main.scss";

interface MainProps extends Children {
  background?: string;
}

export default function Main({ children, background }: MainProps) {
  const inlineStyle = background
    ? `background: ${background} !important;`
    : undefined;

  return (
    <main style={inlineStyle ? { backgroundColor: background } : undefined}>
      {children}
    </main>
  );
}
