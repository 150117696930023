import React from "react";
import "./ShowroomItem.scss";
import { FiPhone, FiMail } from "react-icons/fi";
import AdjustableButton from "../../AdjustableButton/AdjustableButton";
import UnderlinedButton from "../../UnderlinedButton/UnderlinedButton";

interface ShowroomItemProps {
  place: string;
  address: string;
  phone_number: string;
  email: string;
  path: string;
  hours_week: string;
  hours_weekend: string;
  ImgPath: string;
  vis_path: string;
  reverse?: boolean;
}

export default function ShowroomItem(props: ShowroomItemProps) {
  const {
    place,
    address,
    phone_number,
    email,
    path,
    hours_week,
    hours_weekend,
    ImgPath,
    vis_path,
    reverse = false,
  } = props;

  return (
    <div className={`showroom-item ${reverse ? "reverse" : ""}`}>
      <div className="showroom-text">
        <h2 className="place">{place}</h2>
        <p className="address">{address}</p>
        <h2 className="hours">GODZINY OTWARCIA</h2>
        <p className="p-hours">
          Pn-Sb <span>{hours_week}</span>
        </p>
        <p className="p-hours">
          Niedziela handlowa <span>{hours_weekend}</span>
        </p>
        <p className="mail">
          <FiMail />
          <a href={`mailto:${email}`}>WYŚLIJ E-MAIL</a>
        </p>
        <p className="call">
          <FiPhone />
          <a href={`tel:${phone_number}`}>ZADZWOŃ {phone_number}</a>
        </p>
        <AdjustableButton text={"Umów wizytę w salonie"} href={path} />
      </div>
      <div className="showroom-image">
        <img src={ImgPath} alt={place} />
        {/* <UnderlinedButton
          content={"Zobacz wizualizację w 3D"}
          path={vis_path}
        /> */}
      </div>
    </div>
  );
}
