import React, { useEffect } from "react";
import { Button, Form, Input, Modal } from "antd";
import { ICollection } from "../../../types/ICollection";
import { useForm } from "antd/es/form/Form";

type Props = {
  visible: boolean;
  onCancel: () => void;
  onSubmit: (values: ICollection) => void;
  initialValues?: ICollection | null;
  loading: boolean;
};

export default function CollectionModal({
  visible,
  onCancel,
  onSubmit,
  initialValues,
  loading,
}: Props) {
  const [form] = useForm();

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(initialValues);
    } else {
      form.resetFields();
    }
  }, [initialValues]);

  const handleFormSubmit = (values: ICollection) => {
    onSubmit(values);
  };

  return (
    <Modal
      title={initialValues ? "Edytuj kolekcję" : "Dodaj nową kolekcję"}
      open={visible}
      onCancel={onCancel}
      footer={null}
    >
      <Form form={form} layout="vertical" onFinish={handleFormSubmit}>
        <Form.Item
          name="name"
          label="Nazwa kolekcji"
          rules={[{ required: true, message: "Wprowadź nazwę kolekcji" }]}
        >
          <Input placeholder="Wprowadź nazwę kolekcji" />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" block loading={loading}>
            {initialValues ? "Zaktualizuj kolekcję" : "Dodaj kolekcję"}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
