import { useApi } from "./useApi";
import { ICollection } from "../types/ICollection";
import { useAuth } from "./useAuth";
import { useAsync } from "./useAsync";
import { IStatus } from "../types/IStatus";

export interface ICollectionsResponse {
  collection: ICollection;
  status: IStatus;
}

export const useCollection = () => {
  const { api } = useApi();
  const { auth } = useAuth();

  const collection = {
    getAll: useAsync(async (): Promise<ICollectionsResponse[]> => {
      const response = await api.get<ICollectionsResponse[]>(
        "/public/collection"
      );
      return response;
    }),

    get: useAsync(async (id: number): Promise<ICollection> => {
      const response = await api.get<ICollection>(`/public/collection/${id}`);
      return response;
    }),

    create: useAsync(
      async (newCollection: ICollection): Promise<ICollection> => {
        auth.validate();
        const response = await api.post<ICollection>(
          "/moderator/collection",
          newCollection
        );
        return response;
      }
    ),

    update: useAsync(
      async (
        id: number,
        updatedCollection: ICollection
      ): Promise<ICollection> => {
        auth.validate();
        const response = await api.put<ICollection>(
          `/moderator/collection/${id}`,
          updatedCollection
        );
        return response;
      }
    ),

    delete: useAsync(async (id: number): Promise<void> => {
      auth.validate();
      await api.delete(`/moderator/collection/${id}`);
    }),
  };

  return { collection };
};
