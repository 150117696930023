import React from 'react';
import './AboutDiamondsHeading.scss';
import Text from '../../Text/Text';

export default function AboutDiamondsHeading() {
    return (
        <div
            className='AboutDiamonds-heading'
            style={{ backgroundImage: `url(/images/aboutDiamondsBackground.jpeg)` }}
        >
            <Text
                title={'poszerz wiedzę o DIAMENTACH'}
                text={'Odkryj tajemnice diamentów i zgłęb swoją wiedzę na ich temat. Poznaj ich niezwykłą historię, piękno oraz zanurz się  w fascynującym świecie tych kamieni szlachetnych.'}
                showButton={false}
                align={'text-center'}
                content={''}
                path={''}
            />
        </div>
    );
}