import React, { useEffect } from "react";
import { Button, Form, Input, Modal } from "antd";
import { useForm } from "antd/es/form/Form";
import { IUser } from "../../../types/IUser";

type Props = {
  visible: boolean;
  onCancel: () => void;
  onSubmit: (values: IUser) => void;
  initialValues?: IUser | null;
  loading: boolean;
};

export default function UserModal({
  visible,
  onCancel,
  onSubmit,
  initialValues,
  loading,
}: Props) {
  const [form] = useForm();

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(initialValues);
    } else {
      form.resetFields();
    }
  }, [initialValues]);

  const handleFormSubmit = (values: IUser) => {
    onSubmit(values);
  };

  return (
    <Modal
      title={initialValues ? "Edytuj użytkownika" : "Dodaj nowego użytkownika"}
      open={visible}
      onCancel={onCancel}
      footer={null}
    >
      <Form form={form} layout="vertical" onFinish={handleFormSubmit}>
        <Form.Item
          name="username"
          label="Nazwa użytkownika"
          rules={[{ required: true, message: "Wprowadź nazwę użytkownika" }]}
        >
          <Input placeholder="Wprowadź nazwę użytkownika" />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          rules={[{ required: true, message: "Wprowadź email" }]}
        >
          <Input placeholder="Wprowadź email" type="email" />
        </Form.Item>
        <Form.Item
          name="password"
          label="Hasło"
          rules={[{ required: true, message: "Wprowadź hasło" }]}
        >
          <Input.Password placeholder="Wprowadź hasło" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" block loading={loading}>
            {initialValues ? "Zaktualizuj użytkownika" : "Dodaj użytkownika"}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
