import React from "react";
import "./Navbar.scss";
// import useNavbarVisibility from "../../../hooks/useNavbarVisibility";
import SmallNav from "./SmallNav/SmallNav";
import BigNav from "./BigNav/BigNav";

export default function Navbar() {
  //   const { isVisible, changeVisibility } = useNavbarVisibility();

  return (
    <>
      <BigNav />
      <SmallNav />
    </>
  );
}
