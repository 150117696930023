import React, { useEffect } from "react";
import { Button, Form, Input, Modal } from "antd";
import { useForm } from "antd/es/form/Form";
import { ICategory } from "../../../types/ICategory";
type Props = {
  visible: boolean;
  onCancel: () => void;
  onSubmit: (values: ICategory) => void;
  initialValues?: ICategory | null;
  loading: boolean;
};

export default function CategoryModal({
  visible,
  onCancel,
  onSubmit,
  initialValues,
  loading,
}: Props) {
  const [form] = useForm();

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(initialValues);
    } else {
      form.resetFields();
    }
  }, [initialValues]);

  const handleFormSubmit = (values: ICategory) => {
    onSubmit(values);
  };

  return (
    <Modal
      title={initialValues ? "Edytuj kategorię" : "Dodaj nową kategorię"}
      open={visible}
      onCancel={onCancel}
      footer={null}
    >
      <Form form={form} layout="vertical" onFinish={handleFormSubmit}>
        <Form.Item
          name="name"
          label="Nazwa kategorii"
          rules={[{ required: true, message: "Wprowadź nazwę kategorii" }]}
        >
          <Input placeholder="Wprowadź nazwę kategorii" />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" block loading={loading}>
            {initialValues ? "Zaktualizuj kategorię" : "Dodaj kategorię"}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
