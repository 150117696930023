import { useApi } from "./useApi";
import { ICategory } from "../types/ICategory";
import { useAuth } from "./useAuth";
import { useAsync } from "./useAsync";
import { IStatus } from "../types/IStatus";

export interface ICategoriesResponse {
  category: ICategory;
  status: IStatus;
}

export const useCategory = () => {
  const { api } = useApi();
  const { auth } = useAuth();

  const category = {
    getAll: useAsync(async (): Promise<ICategoriesResponse[]> => {
      const response = await api.get<ICategoriesResponse[]>("/public/category");
      return response;
    }),
    get: useAsync(async (id: number): Promise<ICategory> => {
      const response = await api.get<ICategory>(`/public/category/${id}`);
      return response;
    }),

    create: useAsync(async (newCategory: ICategory): Promise<ICategory> => {
      auth.validate();
      const response = await api.post<ICategory>(
        "/moderator/category",
        newCategory
      );
      return response;
    }),

    update: useAsync(
      async (id: number, updatedCategory: ICategory): Promise<ICategory> => {
        auth.validate();
        const response = await api.put<ICategory>(
          `/moderator/category/${id}`,
          updatedCategory
        );
        return response;
      }
    ),

    delete: useAsync(async (id: number): Promise<void> => {
      auth.validate();
      await api.delete(`/moderator/category/${id}`);
    }),
  };

  return { category };
};
