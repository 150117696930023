import Page from "../../components/Page/Page";
import Catalog from "../Collections/Catalog/Catalog";
import Sort from "../Collections/Sort/Sort";
import "./Categories.scss";
import OpeningSection from "./OpeningSection/OpeningSection";

export default function Categories() {
  return (
    <Page background="white">
      <OpeningSection />
      <Sort />
      <Catalog photo={false} title="" />
      <Catalog photo={false} title="" />
    </Page>
  );
}
