import { useApi } from "./useApi";
import { useAuth } from "./useAuth";
import { useAsync } from "./useAsync";
import { IPicture } from "../types/IPicture";

export const usePicture = () => {
  const { api } = useApi();
  const { auth } = useAuth();

  const picture = {
    create: useAsync(async (newPicture: IPicture): Promise<IPicture> => {
      auth.validate();
      const response = await api.post<IPicture>(
        "/moderator/picture",
        newPicture
      );
      return response;
    }),

    // encode: async (file: File): Promise<string> => {
    //   return new Promise((resolve, reject) => {
    //     const reader = new FileReader();
    //     reader.readAsDataURL(file);
    //     reader.onload = () => resolve(reader.result as string);
    //     reader.onerror = (error) => reject(error);
    //   });
    // },

    encode: async (file: File): Promise<string> => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          const base64String = (reader.result as string).split(",")[1];
          resolve(base64String);
        };
        reader.onerror = (error) => reject(error);
      });
    },

    decode: (base64String: string): string => {
      return base64String;
    },
  };

  return { picture };
};
