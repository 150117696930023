import "./CustomMadeSection.scss";
import Text from "../Text/Text";

export default function CustomMadeSection() {
  return (
    <div className="CustomPhoto">
      <Text
        title={"PRODUKTY DOSTĘPNE NA ZAMÓWIENIE"}
        text={
          "Dopasuj biżuterię do Swoich potrzeb dzięki indywidualnej personalizacji.  "
        }
        showButton
        align={"text-right"}
        content={"POZNAJ OFERTĘ"}
        path={""}
        adjustableButton
      />
      <div className="imgWrapper">
        <img src="/images/CustomMade.png"></img>
      </div>
    </div>
  );
}
