import { ReactNode } from "react";
// const TikTokIcon = "../../../icons/TikTok.svg";
import TikTokIcon from "../icons/TikTok.svg";
import FacebookIcon from "../icons/Facebook.svg";
import InstagramIcon from "../icons/Instagram.svg";
import LinkedinIcon from "../icons/Linkedin.svg";
import EnvelopeIcon from "../icons/envelope.svg";
import PhoneIcon from "../icons/phoneGold.svg";
import CallendarIcon from "../icons/callendarGold.svg";
import MarkIcon from "../icons/mark.svg";

interface Link {
  text: string;
  href: string;
  icon?: string;
}

interface FooterSection {
  title: string;
  links: Link[];
}
const footerData: FooterSection[] = [
  {
    title: "O NAS",
    links: [
      { text: "O M&M Gold", href: "#" },
      { text: "Jubiler Gwiazd", href: "#" },
    ],
  },
  {
    title: "NASZE SALONY",
    links: [
      { text: "Galeria Północna", href: "#", icon: MarkIcon },
      { text: "CH Panorama", href: "#", icon: MarkIcon },
      { text: "CH Modo", href: "#", icon: MarkIcon },
      { text: "CH Klif", href: "#", icon: MarkIcon },
      { text: "Poznań Stary Browar", href: "#", icon: MarkIcon },
      { text: "Marbella", href: "#", icon: MarkIcon },
    ],
  },
  {
    title: "INFORMACJE",
    links: [
      { text: "Polityka prywatności", href: "#" },
      { text: "Rozmiary pierścionków", href: "#" },
      { text: "Regulamin", href: "#" },
      { text: "Wiedza o diamentach", href: "#" },
      { text: "FAQ", href: "#" },
    ],
  },
  {
    title: "DOŁĄCZ DO NAS",
    links: [
      { text: "TikTok", href: "#", icon: TikTokIcon },
      { text: "Instagram", href: "#", icon: InstagramIcon },
      { text: "Facebook", href: "#", icon: FacebookIcon },
      { text: "LinkedIn", href: "#", icon: LinkedinIcon },
    ],
  },
  {
    title: "KONTAKT",
    links: [
      { text: "+48 787 787 777", href: "tel:+48787787777", icon: PhoneIcon },
      {
        text: "mmgold@mmgold.pl",
        href: "mailto:mmgold@mmgold.pl",
        icon: EnvelopeIcon,
      },
      { text: "Umów spotkanie w salonie", href: "#", icon: CallendarIcon },
    ],
  },
];

export default footerData;
