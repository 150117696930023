import React, { useState } from 'react';
import { Tabs } from 'antd';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './FAQQuestions.scss';

const { TabPane } = Tabs;

type FAQItem = {
  question: string;
  answer: string;
};

type FAQData = {
  [key: string]: FAQItem[];
};

const faqData: FAQData = {
  ogolne: [
    {
      question: "JAKIE METODY PŁATNOŚCI AKCEPTUJECIE? ogolne",
      answer: "Przyjmujemy przelewy oraz płatność gotówką lub kartą w salonie.",
    },
    {
      question: "CZY PRZYJMUJECIE PŁATNOŚCI W WALUTACH INNYCH NIŻ PLN?",
      answer: "Tak, oprócz PLN honorujemy też euro (również w przypadku przelewów, mamy oddzielne konto bankowe w EUR).",
    },
    {
      question: "CZY MOŻNA PŁACIĆ ZA ZAMÓWIENIE PRZY ODBIORZE?",
      answer: "Tak, oczywiście. Jedynym wyjątkiem są produkty dostępne na zamówienie, za które przyjmujemy zaliczki.",
    },
  ],
  produkty: [
    {
      question: "JAKIE METODY PŁATNOŚCI AKCEPTUJECIE? produkty",
      answer: "Przyjmujemy przelewy oraz płatność gotówką lub kartą w salonie.",
    },
    {
      question: "CZY PRZYJMUJECIE PŁATNOŚCI W WALUTACH INNYCH NIŻ PLN?",
      answer: "Tak, oprócz PLN honorujemy też euro (również w przypadku przelewów, mamy oddzielne konto bankowe w EUR).",
    },
    {
      question: "CZY MOŻNA PŁACIĆ ZA ZAMÓWIENIE PRZY ODBIORZE?",
      answer: "Tak, oczywiście. Jedynym wyjątkiem są produkty dostępne na zamówienie, za które przyjmujemy zaliczki.",
    },
  ],
  wysylka: [
    {
      question: "JAKIE METODY PŁATNOŚCI AKCEPTUJECIE? wysylka",
      answer: "Przyjmujemy przelewy oraz płatność gotówką lub kartą w salonie.",
    },
    {
      question: "CZY PRZYJMUJECIE PŁATNOŚCI W WALUTACH INNYCH NIŻ PLN?",
      answer: "Tak, oprócz PLN honorujemy też euro (również w przypadku przelewów, mamy oddzielne konto bankowe w EUR).",
    },
    {
      question: "CZY MOŻNA PŁACIĆ ZA ZAMÓWIENIE PRZY ODBIORZE?",
      answer: "Tak, oczywiście. Jedynym wyjątkiem są produkty dostępne na zamówienie, za które przyjmujemy zaliczki.",
    },
  ],
  uslugi: [
    {
      question: "JAKIE METODY PŁATNOŚCI AKCEPTUJECIE? uslugi",
      answer: "Przyjmujemy przelewy oraz płatność gotówką lub kartą w salonie.",
    },
    {
      question: "CZY PRZYJMUJECIE PŁATNOŚCI W WALUTACH INNYCH NIŻ PLN?",
      answer: "Tak, oprócz PLN honorujemy też euro (również w przypadku przelewów, mamy oddzielne konto bankowe w EUR).",
    },
    {
      question: "CZY MOŻNA PŁACIĆ ZA ZAMÓWIENIE PRZY ODBIORZE?",
      answer: "Tak, oczywiście. Jedynym wyjątkiem są produkty dostępne na zamówienie, za które przyjmujemy zaliczki.",
    },
  ],
};

const FAQQuestions: React.FC = () => {
  const [expandedPanels, setExpandedPanels] = useState<Record<string, boolean>>({});

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpandedPanels((prev) => ({ ...prev, [panel]: isExpanded }));
  };

  return (
    <div className="FAQQuestions">
      <Tabs defaultActiveKey="ogolne">
        {Object.keys(faqData).map((key) => (
          <TabPane tab={key.charAt(0).toUpperCase() + key.slice(1)} key={key}>
            <div className="accordionContent">
              {faqData[key as keyof FAQData].map((item, index) => {
                const panelKey = `${key}-${index}`;
                const isExpanded = expandedPanels[panelKey] || false;

                return (
                  <Accordion
                    key={panelKey}
                    className="accordion"
                    expanded={isExpanded}
                    onChange={handleChange(panelKey)}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ExpandMoreIcon
                          className="accordionIcon"
                          style={{ color: isExpanded ? 'black' : 'white' }}
                        />
                      }
                      className={`accordionHeaderRoot ${isExpanded ? 'accordionHeaderExpanded' : ''}`}
                    >
                      {item.question}
                    </AccordionSummary>
                    <AccordionDetails className="accordionAnswer">
                      {item.answer}
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </div>
          </TabPane>
        ))}
      </Tabs>
    </div>
  );
};

export default FAQQuestions;