import React from "react";
import Page from "../../components/Page/Page";
import  './FAQ.scss';
import FAQHeader from "../../components/FAQ/FAQHeader/FAQHeader";
import FAQQuestions from "../../components/FAQ/FAQHeader/FAQQuestions/FAQQuestions";
export default function FAQ() {
  return (
    <Page>
<FAQHeader />
<div className="questions">
<FAQQuestions />
</div>
    </Page>
  );
}