import React from "react";
import "./ItemCardColor.scss";
import AdjustableButton from "../../AdjustableButton/AdjustableButton";

const colorOptions: Record<string, string> = {
  gold: "/images/gold-color.svg",
  pink: "/images/pink-color.svg",
  silver: "/images/silver-color.svg",
};

export default function ItemCardColor(props: {
  imageSrc: string;
  title: string;
  price: string;
  colors?: string[];
  variant?: "default" | "button";
  path: string;
}) {
  const { imageSrc, title, price, path, colors = [], variant = "default" } = props;

  return (
    <div className={`ItemCardColor ${variant === "button" ? "withButton" : ""}`}>
      <img src={imageSrc} alt="Product" className="ItemCardColorImage" />
      <h1>{title}</h1>
      <p>{price}</p>
      <div className="Colors">
        {colors.map((color) =>
          colorOptions[color] ? (
            <img
              key={color}
              src={colorOptions[color]}
              alt={`${color} icon`}
              className="ColorIcon"
            />
          ) : null
        )}
      </div>
      {variant === "button" && (
        <AdjustableButton text={"DODAJ DO KOSZYKA"} href={path}  textColor="black" borderColor="black" backgroundColor="white" hoverBackgroundColor="black" hoverBorderColor="white" hoverTextColor="white"/>
      )}
    </div>
  );
}