import "./FAQHeader.scss";
import Text from "../../Text/Text";

export default function FAQHeader() {
  return (
<div className="FAQHeader">
    <Text title={"FAQ"} text={"Poniżej znajdziesz odpowiedzi na najczęściej zadawane pytania dotyczące naszych usług/produktów. Jeśli nie znajdziesz tutaj odpowiedzi na swoje pytanie, skontaktuj się z nami, a chętnie Ci pomożemy."} showButton={false} align={"text-center"} content={""} path={""} />
</div>
  );
}
