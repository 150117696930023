import React, { useState, useEffect, useRef } from "react";
import "./MothersDay.scss";
import Text from "../../Text/Text";
import ItemCardColor from "../ItemCardColor/ItemCardColor";
import { CustomLeftArrow, CustomRightArrow } from "../../CustomArrows/CustomArrows";

export default function MothersDay() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const itemWidth = 250; 
  const gap = 15; 
  const totalItemWidth = itemWidth + gap;
  const itemsRef = useRef<HTMLDivElement>(null);
  const carouselRef = useRef<HTMLDivElement>(null);

  const items = [
    {
      imageSrc: "/images/FollowUs1.png",
      title: "Stylish Hat",
      price: "$19.99",
      colors: ["pink", "gold", "silver"],
      path: "",
    },
    {
      imageSrc: "/images/FollowUs1.png",
      title: "Stylish Hat",
      price: "$19.99",
      colors: ["pink", "gold", "silver"],
      path: "",
    },
    {
      imageSrc: "/images/FollowUs1.png",
      title: "Stylish Hat",
      price: "$19.99",
      colors: ["pink", "gold", "silver"],
      path: "",
    },
  ];

  const [itemsToShow, setItemsToShow] = useState(0);

  useEffect(() => {
    const updateItemsToShow = () => {
      if (carouselRef.current) {
        const carouselWidth = carouselRef.current.offsetWidth;
        setItemsToShow(Math.floor(carouselWidth / totalItemWidth));
        setCurrentIndex(0);
      }
    };

    updateItemsToShow();
    window.addEventListener("resize", updateItemsToShow);
    return () => window.removeEventListener("resize", updateItemsToShow);
  }, [totalItemWidth]);

  useEffect(() => {
    if (itemsRef.current) {
      itemsRef.current.style.transition = "transform 0.5s ease-in-out";
    }
  }, [currentIndex]);

  const handlePrevClick = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevIndex) => prevIndex - 1);
    }
  };

  const handleNextClick = () => {
    if (currentIndex < items.length - itemsToShow) {
      setCurrentIndex((prevIndex) => Math.min(prevIndex + 1, items.length - itemsToShow));
    }
  };

  const showLeftArrow = currentIndex > 0;
  const showRightArrow = currentIndex < items.length - itemsToShow - 1;

  return (
    <div className="MothersDayContent">
      <div className="MothersDayText">
        <Text
          title={"DZIEŃ MATKI"}
          text={
            "Spraw swojej mamie niezapomniany prezent. Nasza kolekcja biżuterii to wyjątkowe upominki, które wyrażają miłość i wdzięczność."
          }
          showButton={false}
          align={"text-center"}
          content={""}
          path={"#"}
        />
        <div className="carousel" ref={carouselRef}>
          {showLeftArrow && <CustomLeftArrow onClick={handlePrevClick} />}
          <div
            className="carousel-inner"
            style={{ transform: `translateX(-${currentIndex * totalItemWidth}px)` }}
            ref={itemsRef}
          >
            {items.map((item, index) => (
              <ItemCardColor
                key={index}
                imageSrc={item.imageSrc}
                title={item.title}
                price={item.price}
                colors={item.colors}
                path={item.path}
              />
            ))}
          </div>
          {showRightArrow && <CustomRightArrow onClick={handleNextClick} />}
        </div>
      </div>
      <div className="MothersDayImage">
        <img src="./images/FollowUs1.png" alt="" />
      </div>
    </div>
  );
}