import "./YourOrder.scss";

const items = [
  {
    imageSrc: "/images/FollowUs1.png",
    product: 'Pierścionek “Emerald Cut Double Halo Limited™"',
    quantity: "1",
    price: "46 000",
  },
  {
    imageSrc: "/images/FollowUs2.png",
    product: 'Pierścionek “Emerald Cut Double Halo Limited™"',
    quantity: "1",
    price: "46 000",
  },
  {
    imageSrc: "/images/FollowUs3.png",
    product: 'Pierścionek “Emerald Cut Double Halo Limited™"',
    quantity: "1",
    price: "46 000",
  },
  {
    imageSrc: "/images/FollowUs4.png",
    product: 'Pierścionek “Emerald Cut Double Halo Limited™"',
    quantity: "1",
    price: "46 000",
  },
  {
    imageSrc: "/images/FollowUs5.png",
    product: 'Pierścionek “Emerald Cut Double Halo Limited™"',
    quantity: "1",
    price: "46 000",
  },
];

export default function YourOrder() {
  return (
    <div className="YourOrder">
      <h1>TWOJE ZAMOWIENIE</h1>
      <div className="YourOrderCart">
        <h2>PRODUKTY</h2>
        <div className="ProductList">
          {items.map((item, index) => (
            <div className="Product" key={index}>
              <img src={item.imageSrc} alt={item.product} className="ProductImage" />
                <p className="ProductName">{item.product}</p>
                <p className="ProductQuantity">{item.quantity} x</p>
                <p className="ProductPrice"> {item.price} zł</p>
              </div>

          ))}
        </div>
      </div>
      <div className="Summary">
        <div className="row">
            <p>SUMA ZAMÓWIENIA:</p>
            <p className="answer">104 000zł</p>
        </div>
        <div className="row">
          <p>DOSTAWA:</p>
          <p className="answer">0zł</p>
        </div>
        <div className="row">
            <p>SPOSÓB DOSTAWY:</p>
            <p className="answer">szybka przesyłka kurierska</p>
        </div>
        <div className="row">
            <p>METODA PŁATNOŚCI:</p>
            <p className="answer">przelew na konto</p>
        </div>
        <div className="row last">
<p>CAŁKOWITY KOSZT ZAMÓWIENIA:</p>
<p className="answer">104 000zł</p>
        </div>
      </div>
    </div>
  );
}