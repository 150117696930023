import React from "react";
import "./SizesTable.scss";

type DataTable = {
  size: string | undefined;
  england: string | undefined;
  us: string | undefined;
  circumference: string | undefined;
  diameter: string | undefined;
};

export default function SizesTable(props: {
  data: DataTable[];
  image: string;
}) {
  const { data, image } = props;

  return (
    <div
      style={{
        height: "600px",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "5%",
      }}
    >
      <table className="sizeTable">
        <thead>
          <tr>
            <th>Rozmiar</th>
            <th>Anglia</th>
            <th>US</th>
            <th>Obwód (mm)</th>
            <th>Średnica (mm)</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => {
            const opacity = 0.7 - index * 0.05;
            return (
              <tr key={index}>
                <td
                  style={{
                    borderBottom: `1px solid rgba(0, 0, 0, ${opacity})`,
                  }}
                >
                  {row.size}
                </td>
                <td
                  style={{
                    borderBottom: `1px solid rgba(0, 0, 0, ${opacity})`,
                  }}
                >
                  {row.england}
                </td>
                <td
                  style={{
                    borderBottom: `1px solid rgba(0, 0, 0, ${opacity})`,
                  }}
                >
                  {row.us}
                </td>
                <td
                  style={{
                    borderBottom: `1px solid rgba(0, 0, 0, ${opacity})`,
                  }}
                >
                  {row.circumference}
                </td>
                <td
                  style={{
                    borderBottom: `1px solid rgba(0, 0, 0, ${opacity})`,
                  }}
                >
                  {row.diameter}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="sizePhoto">
        <img src={`/images/${image}`} alt="" height={"100%"} />
      </div>
    </div>
  );
}
