import React from 'react';
import AdjustableButton from '../AdjustableButton/AdjustableButton';
import './Sizeer.scss';

const Sizeer = () => {
    return (
        <div className="sizeer">
            <div className="content">
                <div className="text-section">
                    <h1>ZAMÓW BEZPŁATNY SIZEER</h1>
                    <p>Nie wiesz jaki rozmiar produktu zamówić?
                        <br></br>Z chęcią wyślemy Ci bezpłatny sizeer.
                    </p>
                    <div className="button">
                        <AdjustableButton
                            text="ZAMÓW TERAZ"
                            backgroundColor="transparent"
                            textColor="white"
                            borderColor="white"
                            hoverBackgroundColor="white"
                            hoverTextColor="black"
                            hoverBorderColor="black"
                            href="/order-sizeer"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Sizeer;
