import { Carousel } from "antd";
import "./ProductDetails.scss";
import { ReactComponent as SilverIcon } from "../../../icons/silver.svg";
import { ReactComponent as GoldIcon } from "../../../icons/gold.svg";
import { ReactComponent as PinkGoldIcon } from "../../../icons/pinkGold.svg";
import { ReactComponent as HeartIcon } from "../../../icons/heart.svg";
import { ReactComponent as PhoneIcon } from "../../../icons/phone.svg";
import { ReactComponent as CallendarIcon } from "../../../icons/callendar.svg";
import { ReactComponent as BellIcon } from "../../../icons/bell.svg";
import AdjustableButton from "../../../components/AdjustableButton/AdjustableButton";
import { PlusOutlined } from "@ant-design/icons";

const images = [
  { imageSrc: "/images/product1.png" },
  { imageSrc: "/images/product2.png" },
  { imageSrc: "/images/product1.png" },
  { imageSrc: "/images/product2.png" },
];

const product = {
  nazwa: "ROLEX Discontinued Datejust",
  opis: "Rolex Sky-Dweller jest jednym z najbardziej zaawansowanych technicznie zegarków w kolekcji Rolex. To prawdziwy przykład innowacyjności i zaawansowanej technologii w świecie zegarmistrzostwa.",
  cena: "49 450 EUR",
};

export default function ProductDetails() {
  return (
    <>
      <div className="ProductDetails">
        <div className="CarouselContainer">
          <Carousel
            arrows
            dots={false}
            infinite={true}
            slidesToShow={2}
            slidesToScroll={1}
          >
            {images.map((image) => (
              <img src={image.imageSrc} className="sliderItem" />
            ))}
          </Carousel>
        </div>
        <div className="DetailsContainer">
          <h2>{product.nazwa}</h2>
          <p className="opis">{product.opis}</p>
          <GoldIcon />
          <SilverIcon />
          <PinkGoldIcon />
          <p className="cena">{product.cena}</p>
          <div className="buttons">
            <AdjustableButton text={"DODAJ DO KOSZYKA"} href={""} />
            <button
              // onClick={() => (window.location.href = "/cart")}
              style={{
                background: "none",
                border: "none",
                padding: 0,
                cursor: "pointer",
              }}
            >
              <HeartIcon className="heartIcon" />
            </button>
          </div>
          <div className="collapsable">
            <div className="collapsableItem">
              SZCZEGÓŁY <PlusOutlined />
            </div>
            <div className="collapsableItem">
              WYSYŁKA I ZWROT <PlusOutlined />
            </div>
          </div>
        </div>
      </div>
      <div className="actionsRow">
        <div className="actionItem">
          <PhoneIcon stroke="white" />
          ZAMÓW PRZEZ TELEFON
        </div>
        <div className="divider" />
        <div className="actionItem">
          <CallendarIcon />
          UMÓW WIZYTĘ W SALONIE
        </div>
        <div className="divider" />
        <div className="actionItem">
          <BellIcon />
          SKONTAKTUJ SIĘ Z EKSPERTEM
        </div>
      </div>
    </>
  );
}
