import { useApi } from "./useApi";
import { IType } from "../types/IType";
import { useAuth } from "./useAuth";
import { useAsync } from "./useAsync";
import { IStatus } from "../types/IStatus";

export interface ITyoesResponse {
  type: IType;
  status: IStatus;
}

export const useType = () => {
  const { api } = useApi();
  const { auth } = useAuth();

  const type = {
    getAll: useAsync(async (): Promise<IType[]> => {
      const response = await api.get<IType[]>("/public/type");
      return response;
    }),

    get: useAsync(async (id: number): Promise<IType> => {
      const response = await api.get<IType>(`/public/type/${id}`);
      return response;
    }),

    create: useAsync(async (newType: IType): Promise<IType> => {
      auth.validate();
      const response = await api.post<IType>("/moderator/type", newType);
      return response;
    }),

    update: useAsync(async (id: number, updatedType: IType): Promise<IType> => {
      auth.validate();
      const response = await api.put<IType>(
        `/moderator/type/${id}`,
        updatedType
      );
      return response;
    }),

    delete: useAsync(async (id: number): Promise<void> => {
      auth.validate();
      await api.delete(`/moderator/type/${id}`);
    }),
  };

  return { type };
};
