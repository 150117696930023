import React, { useEffect, useState } from "react";
import "./BigNav.scss";
import useNavbarVisibility from "../../../hooks/useNavbarVisibility";
import { Dropdown, MenuProps, Space } from "antd";
import { DownOutlined, EnvironmentOutlined } from "@ant-design/icons";
import { IoCallOutline } from "react-icons/io5";
import AdjustableButton from "../../AdjustableButton/AdjustableButton";
import {
  PiTiktokLogoThin,
  PiInstagramLogoLight,
  PiFacebookLogoThin,
} from "react-icons/pi";
import { ReactComponent as ShoppingBagIcon } from "../../../icons/shopping-bag.svg";
import { ReactComponent as HeartIcon } from "../../../icons/heart.svg";
import { ReactComponent as AccountIcon } from "../../../icons/account.svg";

const currency: MenuProps["items"] = [
  {
    label: "USD",
    key: "1",
  },
  {
    label: "EUR",
    key: "2",
  },
];

const language: MenuProps["items"] = [
  {
    label: "ANGIELSKI",
    key: "1",
  },
  {
    label: "HISZPAŃSKI",
    key: "2",
  },
];

export default function BigNav() {
  const { isVisible, changeVisibility } = useNavbarVisibility();
  const [isCurrencyOpen, setIsCurrencyOpen] = useState(false);
  const [isLanguageOpen, setIsLanguageOpen] = useState(false);

  const handleLanguageClick = (e: any) => {
    e.preventDefault();
    setIsLanguageOpen(!isLanguageOpen);
  };

  const handleCurrencyClick = (e: any) => {
    e.preventDefault();
    setIsCurrencyOpen(!isCurrencyOpen);
  };

  const handleCurrencyChange = (flag: any) => {
    setIsCurrencyOpen(flag);
  };
  const handleLanguageChange = (flag: any) => {
    setIsLanguageOpen(flag);
  };

  return (
    <div className="bigNav">
      <div className="bigNav_left">
        <span>
          <IoCallOutline
            style={{ marginBottom: "-3px", marginRight: "10px" }}
          />
          <a href="/contact">KONTAKT</a>
        </span>

        <span>
          <EnvironmentOutlined
            style={{ marginBottom: "-3px", marginRight: "10px" }}
          />
          <a href="/showrooms">SALONY</a>
        </span>
      </div>

      <div className="bigNav_center">
        <img
          src={"/images/MMGoldLogo.png"}
          alt={"Logo M&M Gold"}
          height={"100%"}
          style={{ cursor: "pointer" }}
          onClick={() => (window.location.href = "/")}
        />
      </div>

      <div className="bigNav_right">
        <div className="bigNav_right_Top">
          <div className="icons">
            <PiTiktokLogoThin className="icon" />
            <PiInstagramLogoLight className="icon" />
            <PiFacebookLogoThin className="icon" />
          </div>
          <AdjustableButton text={"UMÓW WIZYTĘ"} href={""} />
          <Dropdown
            menu={{
              items: language,
              style: { backgroundColor: "transparent" },
            }}
            placement="bottom"
            trigger={["click"]}
            onOpenChange={handleLanguageChange}
          >
            <a onClick={handleLanguageClick}>
              <Space>
                POLSKI
                <DownOutlined
                  className={`dropdown-icon ${isLanguageOpen ? "rotated" : ""}`}
                />
              </Space>
            </a>
          </Dropdown>
          <Dropdown
            menu={{
              items: currency,
              style: { backgroundColor: "transparent" },
            }}
            placement="bottom"
            trigger={["click"]}
            onOpenChange={handleCurrencyChange}
          >
            <a onClick={handleCurrencyClick}>
              <Space className="dropdown-space">
                PLN
                <DownOutlined
                  className={`dropdown-icon ${isCurrencyOpen ? "rotated" : ""}`}
                />
              </Space>
            </a>
          </Dropdown>
        </div>
        <div className="bigNav_right_Bottom">
          <button
            onClick={() => (window.location.href = "/cart")}
            style={{
              background: "none",
              border: "none",
              padding: 0,
              cursor: "pointer",
            }}
          >
            <ShoppingBagIcon className="icon" />
          </button>
          <button
            onClick={() => console.log("Heart!")}
            style={{
              background: "none",
              border: "none",
              padding: 0,
              cursor: "pointer",
            }}
          >
            <HeartIcon className="icon" />
          </button>
          <button
            onClick={() => console.log("account!")}
            style={{
              background: "none",
              border: "none",
              padding: 0,
              cursor: "pointer",
            }}
          >
            <AccountIcon className="icon" />
          </button>
        </div>
      </div>
    </div>
  );
}
