import React, { useState, useEffect } from "react";
import "./NavDrawer.scss";
import { NavDrawerTabs } from "./NavDrawerTabs/NavDrawerTabs";

interface DrawerProps {
  open: boolean;
  data: string;
}

interface Option {
  key: string;
  label: string;
}

const products: Option[] = [
  { key: "1", label: "NASZYJNIKI" },
  { key: "2", label: "BRANSOLETKI" },
  { key: "3", label: "PIERŚCIONKI" },
  { key: "4", label: "KOLCZYKI" },
  { key: "5", label: "ZEGARKI" },
  { key: "6", label: "BROSZKI" },
  { key: "7", label: "BRYLANTY" },
];

const collections: Option[] = [
  { key: "1", label: "HIGH JEWELS" },
  { key: "2", label: "INVISIBLE" },
  { key: "3", label: "ZARĘCZYNY" },
  { key: "4", label: "ANTYCZNE" },
];

const forHim: Option[] = [
  { key: "1", label: "BRANSOLETKI" },
  { key: "2", label: "SYGNETY" },
  { key: "3", label: "ŁAŃCUSZKI" },
  { key: "4", label: "BRELOKI" },
];

export default function NavDrawer(props: DrawerProps) {
  const { open, data } = props;

  const [options, setOptions] = useState<Option[]>([]);

  useEffect(() => {
    if (!open) {
      return;
    }

    if (data === "products") {
      setOptions(products);
    } else if (data === "for-him") {
      setOptions(forHim);
    } else {
      setOptions(collections);
    }
  }, [data, open]);

  return (
    <div className={`dropdown-content ${open ? "show-content" : ""}`}>
      <NavDrawerTabs options={options} />
    </div>
  );
}
