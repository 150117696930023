export const formatDate = (dateArray: number[]) => {
  if (!Array.isArray(dateArray) || dateArray.length !== 6) {
    return "Niepoprawny format daty";
  }

  const [year, month, day, hours, minutes, seconds] = dateArray;
  return `${String(day).padStart(2, "0")}-${String(month).padStart(
    2,
    "0"
  )}-${year} ${String(hours).padStart(2, "0")}:${String(minutes).padStart(
    2,
    "0"
  )}:${String(seconds).padStart(2, "0")}`;
};

export const getTableTypeName = (table: string) => {
  switch (table) {
    case "collections":
      return "Kolekcje";
    case "types":
      return "Typy";
    case "supercategories":
      return "Superkategorie";
    case "categories":
      return "Kategorie";
    case "users":
      return "Użytkownicy";
    default:
      return table;
  }
};

export const getModalType = (tableName: string) => {
  switch (tableName) {
    case "collections":
      return "collection";
    case "types":
      return "type";
    case "supercategories":
      return "superCategory";
    case "categories":
      return "category";
    default:
      return null;
  }
};
