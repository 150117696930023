import React, { useEffect, useState } from "react";
import { Modal, Table } from "antd";
import { useCategory } from "../../../hooks/useCategory";
import { useCollection } from "../../../hooks/useCollection";
import { useType } from "../../../hooks/useType";
import { useSuperCategory } from "../../../hooks/useSuperCategory";
import "./UniversalLogModal.scss";

interface ChangeDetailsModalProps {
  visible: boolean;
  onClose: () => void;
  log: Record<string, any> | null;
  type: "category" | "collection" | "type" | "superCategory";
}

const UniversalLogModal = ({
  visible,
  onClose,
  log,
  type,
}: ChangeDetailsModalProps) => {
  const { category } = useCategory();
  const { collection } = useCollection();
  const { type: typeHook } = useType();
  const { supercategory } = useSuperCategory();

  const [currentData, setCurrentData] = useState<Record<string, any> | null>(
    null
  );
  const [previousData, setPreviousData] = useState<Record<string, any> | null>(
    null
  );

  const getService = () => {
    switch (type) {
      case "category":
        return category.get;
      case "collection":
        return collection.get;
      case "type":
        return typeHook.get;
      case "superCategory":
        return supercategory.get;
      default:
        return null;
    }
  };

  const getDataMapping = (data: Record<string, any> | null) => {
    if (!data) return { id: "", name: "", status: "" };

    switch (type) {
      case "category":
        return {
          id: data?.category?.id || "",
          name: data?.category?.name || "",
          status: data?.status?.name || "",
        };
      case "collection":
        return {
          id: data?.collection?.id || "",
          name: data?.collection?.name || "",
          status: data?.status?.name || "",
        };
      case "type":
        return {
          id: data?.type?.id || "",
          name: data?.type?.name || "",
          status: data?.status?.name || "",
        };
      case "superCategory":
        return {
          id: data?.superCategory?.id || "",
          name: data?.superCategory?.name || "",
          status: data?.status?.name || "",
        };
      default:
        return {
          id: "",
          name: "",
          status: "",
        };
    }
  };

  useEffect(() => {
    const service = getService();
    if (log?.dataId && service) {
      service.execute(log.dataId).then((responseData: any) => {
        setCurrentData(responseData);

        const previousVersionId = responseData?.[type]?.previousVersionId;

        if (previousVersionId) {
          service
            .execute(previousVersionId)
            .then((previousVersionData: any) => {
              setPreviousData(previousVersionData);
            });
        } else {
          setPreviousData(null);
        }
      });
    } else {
      setCurrentData(null);
      setPreviousData(null);
    }
  }, [log, type]);

  const columns = [
    {
      title: "Pole",
      dataIndex: "field",
      key: "field",
    },
    {
      title: "Poprzednia wartość",
      dataIndex: "oldValue",
      key: "oldValue",
    },
    {
      title: "Aktualna wartość",
      dataIndex: "newValue",
      key: "newValue",
    },
  ];

  const currentMappedData = getDataMapping(currentData);
  const previousMappedData = getDataMapping(previousData);

  const dataSource = [
    // {
    //   key: "1",
    //   field: "ID",
    //   oldValue: previousMappedData.id || "-",
    //   newValue: currentMappedData.id || "-",
    // },
    {
      key: "2",
      field: "Nazwa",
      oldValue: previousMappedData.name || "-",
      newValue: currentMappedData.name || "-",
    },
    // {
    //   key: "3",
    //   field: "Status",
    //   oldValue: previousMappedData.status || "-",
    //   newValue: currentMappedData.status || "-",
    // },
  ];

  const rowClassName = (record: any) => {
    const hasChanged =
      record.oldValue !== record.newValue &&
      record.oldValue !== "-" &&
      record.newValue !== "-";

    return hasChanged ? "highlighted-row" : "";
  };

  return (
    <Modal
      title="Szczegóły zmian"
      open={visible}
      onCancel={() => {
        onClose();
        setCurrentData(null);
        setPreviousData(null);
      }}
      footer={null}
    >
      {currentData ? (
        <Table
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          rowClassName={rowClassName}
        />
      ) : (
        <p>Ładowanie danych...</p>
      )}
    </Modal>
  );
};

export default UniversalLogModal;
