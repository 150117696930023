import React, { useState, useEffect } from "react";
import { Carousel, Tabs } from "antd";
import "./NavDrawerTabs.scss";
import UnderlinedButton from "../../../UnderlinedButton/UnderlinedButton";

interface DrawerTabsProps {
  options: { key: string; label: string }[];
}

const contentStyle: React.CSSProperties = {
  margin: 0,
  height: "160px",
  width: "160px",
  color: "#fff",
  lineHeight: "160px",
  textAlign: "center",
  background: "red",
};

const images = [
  { imageSrc: "/images/FollowUs1.png" },
  { imageSrc: "/images/FollowUs2.png" },
  { imageSrc: "/images/FollowUs3.png" },
  { imageSrc: "/images/FollowUs4.png" },
  { imageSrc: "/images/FollowUs5.png" },
  { imageSrc: "/images/FollowUs6.png" },
];

export const NavDrawerTabs = (props: DrawerTabsProps) => {
  const { options } = props;
  const [activeKey, setActiveKey] = useState<string>(options[0]?.key || "1");

  useEffect(() => {
    setActiveKey(options[0]?.key || "1");
  }, [options]);

  const items = options.map((item) => ({
    key: item.key,
    label: item.label,
    children: (
      <div className="NavDrawerTabsContent">
        <div className="sliderContainer">
          <Carousel
            arrows
            dots={false}
            infinite={true}
            slidesToShow={5}
            slidesToScroll={1}
            className="carousel"
          >
            {images.map((image) => (
              <div className="carouselItem">
                <img
                  src={image.imageSrc}
                  // alt={`Follow us image ${index + 1}`}
                />
              </div>
            ))}
          </Carousel>
        </div>
        <UnderlinedButton content={"Zobacz wszystko"} path={""} />
      </div>
    ),
  }));

  const onChange = (key: string) => {
    setActiveKey(key);
  };

  return (
    <Tabs
      type="line"
      items={items}
      onChange={onChange}
      centered
      size="large"
      activeKey={activeKey}
      className="NavDrawerTabs"
    />
  );
};
