import './InfoSection.scss';
import IconText from './IconText/IconText';

export default function InfoSection() {
    return (
        <div className="info-section">
            <IconText
                icon="diamond"
                title="DOŚWIADCZENIE"
                description="Od lat jesteśmy bezpośrednim importerem brylantów na Polski rynek"
            />
            <IconText
                icon="fact_check"
                title="PROFESJONALIZM"
                description="Kolekcja ART Deco wykonywana jest przez najlepszych jubilerów w Polsce"
            />
            <IconText
                icon="favorite"
                title="INDYWIDUALNE PODEJŚCIE"
                description="Szeroka gama personalizowanych produktów na zamówienie"
            />
        </div>
    );
}
