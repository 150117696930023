import "./FooterIcons.scss";
import { ReactComponent as FacebookIcon } from "../../../icons/Facebook.svg";
import { ReactComponent as LinkedinIcon } from "../../../icons/Linkedin.svg";
import { ReactComponent as TikTokIcon } from "../../../icons/TikTok.svg";
import { ReactComponent as InstagramIcon } from "../../../icons/Instagram.svg";

export default function FooterIcons() {
  return (
    <div className="FooterIconsRow">
      <TikTokIcon />
      <InstagramIcon />
      <FacebookIcon />
      <LinkedinIcon />
    </div>
  );
}
