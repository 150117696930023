import React from "react";
import Page from "../../components/Page/Page";
import AboutDiamondsHeading from "../../components/AboutDiamonds/AboutDiamondsHeading/AboutDiamondsHeading";
import DiamondComparison from "../../components/AboutDiamonds/DiamondComparison/DiamondComparison";
import DiamondFeatures from "../../components/AboutDiamonds/DiamondFeatures/DiamondFeatures";
import DiamondLegality from "../../components/AboutDiamonds/DiamondsLegality/DiamondsLegality";
import Brylanty from "../../components/AboutDiamonds/Brylanty/Brylanty";
export default function AboutDiamonds() {
  return (
    <Page>
<AboutDiamondsHeading />
<DiamondComparison />
<DiamondFeatures />
<DiamondLegality />
<Brylanty />
    </Page>
  );
}
