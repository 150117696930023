import React, { useEffect, useState } from "react";
import { Button, Modal, Steps, theme, Tooltip } from "antd";
import { IProduct } from "../../../types/IProduct";
import AttributeForm from "./AttributeForm";
import PictureUpload from "./PictureUpload";
import ProductForm from "./Product.Form";
import { IPicture } from "../../../types/IPicture";
import { IAttribute } from "../../../types/IAttribute";

type Props = {
  visible: boolean;
  onCancel: () => void;
  onSubmit: (values: IProduct) => void;
  initialValues: IProduct | null;
  loading: boolean;
};

export default function ProductsModal({
  visible,
  onCancel,
  onSubmit,
  initialValues,
  loading,
}: Props) {
  const { token } = theme.useToken();
  const [current, setCurrent] = useState(0);
  const [picturesData, setPicturesData] = useState<IPicture[]>();
  const [attributesData, setAttributesData] = useState<IAttribute>();
  const [productData, setProductData] = useState<IProduct>();
  const [isStepCompleted, setIsStepCompleted] = useState(false);

  const handlePictureData = (data: IPicture[]) => {
    setPicturesData(data);
    setIsStepCompleted(true);
  };
  const handleAttributeData = (data: IAttribute) => {
    setAttributesData(data);
    setIsStepCompleted(true);
  };
  const handleProductData = (data: IProduct) => {
    setProductData(data);
    setIsStepCompleted(true);
  };

  const contentStyle: React.CSSProperties = {
    lineHeight: "70px",
    textAlign: "center",
    color: token.colorTextTertiary,
    backgroundColor: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: `1px dashed ${token.colorBorder}`,
    marginTop: 16,
  };

  const next = () => {
    setCurrent(current + 1);
    setIsStepCompleted(false);
  };

  const prev = () => {
    setCurrent(current - 1);
    setIsStepCompleted(true);
  };

  useEffect(() => {
    setAttributesData(initialValues?.attribute);
    setProductData(initialValues ? initialValues : undefined);
  }, [initialValues]);

  const handleFormSubmit = () => {
    // console.log("picturesData: " + picturesData);
    // console.log("attributesData: " + attributesData);
    // console.log("productData: " + productData);
    if (picturesData && attributesData && productData) {
      const newAttribute: IAttribute = {
        ...attributesData,
        pictures: picturesData,
        isRecommended: attributesData.isRecommended ? 1 : 0,
      };
      const newProduct: IProduct = {
        ...productData,
        attribute: newAttribute,
      };
      onSubmit(newProduct);
      setCurrent(0);
      setPicturesData(undefined);
      setAttributesData(undefined);
      setProductData(undefined);
      setIsStepCompleted(false);
    } else {
      console.error("Brak wymaganych danych");
    }
  };

  const steps = [
    {
      title: "Dodaj zdjęcia",
      content: <PictureUpload onSave={handlePictureData} />,
    },
    {
      title: "Wybierz kategorie",
      content: (
        <ProductForm onSave={handleProductData} initialValues={initialValues} />
      ),
    },
    {
      title: "Zdefiniuj atrybuty",
      content: (
        <AttributeForm
          onSave={handleAttributeData}
          initialValues={initialValues?.attribute}
          product={productData}
        />
      ),
    },
  ];

  const items = steps.map((item) => ({ key: item.title, title: item.title }));

  return (
    <Modal
      title={initialValues ? "Edytuj produkt" : "Dodaj nowy produkt"}
      open={visible}
      onCancel={onCancel}
      footer={null}
      width={"50%"}
      destroyOnClose
    >
      <Steps current={current} items={items} />
      <div style={contentStyle}>{steps[current].content}</div>
      <div style={{ marginTop: 24 }}>
        {current < steps.length - 1 && (
          <Tooltip
            title={!isStepCompleted ? "Zakończ ten krok aby przejść dalej" : ""}
          >
            <Button
              type="primary"
              onClick={() => next()}
              disabled={!isStepCompleted}
            >
              Next
            </Button>
          </Tooltip>
        )}
        {current === steps.length - 1 && (
          <Tooltip
            title={!isStepCompleted ? "Zakończ ten krok aby przejść dalej" : ""}
          >
            <Button
              type="primary"
              onClick={() => handleFormSubmit()}
              disabled={!isStepCompleted}
            >
              Done
            </Button>
          </Tooltip>
        )}
        {current > 0 && (
          <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
            Previous
          </Button>
        )}
      </div>
    </Modal>
  );
}
