import Text from "../../../components/Text/Text";
import "./ProductDescription.scss";

export default function ProductDescription() {
  return (
    <div className="ProductDescription_section">
      <div className="text">
        <Text
          title={"OPIS PRODUKTU"}
          text={
            "Rolex Sky-Dweller jest jednym z najbardziej zaawansowanych technicznie zegarków w kolekcji Rolex. To prawdziwy przykład innowacyjności i zaawansowanej technologii w świecie zegarmistrzostwa."
          }
          showButton={false}
          align={"text-center"}
          content={""}
          path={""}
        />
      </div>
      <img src="./images/productFoto.png" alt="Brylanty" />
    </div>
  );
}
