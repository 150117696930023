import React from "react";
import "./OpeningSection.scss";
import Text from "../../Text/Text";

const OpeningSection = () => {
  return (
    <div className="contact-details">
      <div className="image-container">
        <img src="../images/Contact-OpeningSection.jpg" alt="Contact Details" />
      </div>
      <div className="text-container">
        <Text
          title="KONTAKT"
          text={
            "Jeśli masz jakiekolwiek pytania, skontaktuj się z nami. Poniżej" +
            "\n" +
            " znajdziesz wszystkie potrzebne informacje oraz telefony" +
            "\n" +
            " kontaktowe."
          }
          showButton={false}
          align="text-left"
          content=""
          path=""
        />
      </div>
    </div>
  );
};

export default OpeningSection;
