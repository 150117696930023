import "./UnderlinedButton.scss";
import { IoIosArrowForward } from "react-icons/io";

export default function underlinedButton(props: {
  content: string;
  path: string;
}) {
  const { content, path } = props;

  return (
    <a href={path} className="a-underlinedButton">
      {content} <IoIosArrowForward />
    </a>
  );
}
