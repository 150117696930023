import { Navigate } from "react-router-dom";
import { Children } from "../../../interfaces/children";
import { useStorage } from "../../../hooks/useStorage";
import { IUser } from "../../../types/IUser";
import { message } from "antd";

interface ProtectedRouteProps extends Children {
  role?: "ADMIN" | "MODERATOR";
}

export const ProtectedRoute = ({ children, role }: ProtectedRouteProps) => {
  const { storage } = useStorage();

  const user = storage.session.getJSON<IUser | null>("user");

  if (!user) {
    // console.log(user);
    return <Navigate to="/signin" />;
  }

  if (role && user?.authority !== role) {
    // console.log(user);
    message.warning("Nie masz uprawnień do tej ścieżki.");
    return <Navigate to="/admin" />;
  }

  return <>{children}</>;
};
