import './DiamondFeatures.scss';

const features = [
    {
        path: '/images/DiamondFeatures1.svg',
        title: 'KARAT',
        description: 'Jednostką masy jest karat (około 0,2 grama). Im większa masa kamienia, tym większa jego wartość.'
    },
    {
        path: '/images/DiamondFeatures2.svg',
        title: 'SZLIF',
        description: 'Czynnik, który nie wynika z naturalnych właściwości diamentu, lecz z pracy szlifierza, który nadaje kamieniowi ostateczny kształt. '
    },
    {
        path: '/images/DiamondFeatures3.svg',
        title: 'BARWA',
        description: 'Najdroższe kamienie to te całkowicie bezbarwne i przejrzyste. Do określenia barwy używa się skali oznaczonej literami od D (idealnie bezbarwny) do Z (najbardziej żółta barwa).'
    },
    {
        path: '/images/DiamondFeatures4.svg',
        title: 'CZYSTOŚĆ',
        description: 'Każdy diament ma określoną strukturę, która ma istotny wpływ na cechy kamienia. Im mniej inkluzji i skaz, tym bardziej przezroczysty i droższy kamień.'
    }
];

export default function DiamondFeatures() {
    return (
        <div className="diamondFeatures-content">
            <div className="diamondFeatures-innerContent">
                <h1 className='title'>CECHY DIAMENTÓW</h1>
                <div className="diamondFeatures-row">
                    {features.map((feature, index) => (
                        <div key={index} className="diamondFeature">
                            <img src={feature.path} alt={feature.title} />
                            <h1>{feature.title}</h1>
                            <p>{feature.description}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}