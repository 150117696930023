import React, { useEffect } from "react";
import { Button, Form, Input, Modal } from "antd";
import { useForm } from "antd/es/form/Form";
import { IType } from "../../../types/IType";

type Props = {
  visible: boolean;
  onCancel: () => void;
  onSubmit: (values: IType) => void;
  initialValues?: IType | null;
  loading: boolean;
};

export default function TypesModal({
  visible,
  onCancel,
  onSubmit,
  initialValues,
  loading,
}: Props) {
  const [form] = useForm();

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(initialValues);
    } else {
      form.resetFields();
    }
  }, [initialValues]);

  const handleFormSubmit = (values: IType) => {
    onSubmit(values);
  };

  return (
    <Modal
      title={initialValues ? "Edytuj typ" : "Dodaj nowy typ"}
      open={visible}
      onCancel={onCancel}
      footer={null}
    >
      <Form form={form} layout="vertical" onFinish={handleFormSubmit}>
        <Form.Item
          name="name"
          label="Nazwa typu"
          rules={[{ required: true, message: "Wprowadź nazwę typu" }]}
        >
          <Input placeholder="Wprowadź nazwę typu" />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" block loading={loading}>
            {initialValues ? "Zaktualizuj typ" : "Dodaj typ"}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
