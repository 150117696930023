import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  InputNumber,
  Button,
  Checkbox,
  Row,
  Col,
  DatePicker,
} from "antd";
import { IAttribute } from "../../../types/IAttribute";
import moment from "moment";
import dayjs from "dayjs";
import { IProduct } from "../../../types/IProduct";
import { useVisibleFields } from "../../../hooks/useVisableFields";

interface AttributeFormProps {
  initialValues: IAttribute | undefined;
  onSave: (data: IAttribute) => void;
  product: IProduct | undefined;
}
const dateFormat = "DD-MM-YYYY";

export default function AttributeForm({
  onSave,
  initialValues,
  product,
}: AttributeFormProps) {
  const [form] = Form.useForm();
  const getVisibleFields = useVisibleFields();
  const [visibleFields, setVisibleFields] = useState({
    metal: false,
    goldTest: false,
    stone: false,
    stoneWeight: false,
    clasp: false,
    referenceNumber: false,
    diameter: false,
    mechanism: false,
    dialColor: false,
    productCondition: false,
    included: false,
    productionDate: false,
    color: false,
    clarity: false,
    cutType: false,
    isRecommended: true,
  });

  const calculateChoice = () => {
    if (product?.superCategory?.id === 9) {
      return getVisibleFields("diamenty");
    } else if (product?.superCategory?.id === 8) {
      return getVisibleFields("zegarki");
    } else if (product?.superCategory?.id === 7) {
      if (product.collection?.id === 32) {
        return getVisibleFields("invisible");
      } else if (product.category?.id === 11) {
        return getVisibleFields("pierscionki_zareczynowe");
      } else if (product.category?.id === 12) {
        return getVisibleFields("obraczki_z_diamentami");
      } else if (product.category?.id === 13) {
        return getVisibleFields("naszyjniki_z_diamentami");
      } else if (product.category?.id === 14) {
        return getVisibleFields("bransoletki_z_diamentami");
      } else if (product.category?.id === 15) {
        return getVisibleFields("kolczyki_z_diamentami");
      } else if (product.category?.id === 16) {
        return getVisibleFields("bizuteria_dawna");
      } else if (product.category?.id === 17) {
        return getVisibleFields("bizuteria_art_deco");
      } else if (product.category?.id === 18) {
        return getVisibleFields("bizuteria_platynowa");
      } else if (product.category?.id === 19) {
        return getVisibleFields("high_jewels");
      }
    }
    return getVisibleFields("");
  };

  useEffect(() => {
    const choice = calculateChoice();
    setVisibleFields(choice);
    if (initialValues && product) {
      console.log("PRODUKT: ", product);
      form.setFieldsValue(product.attribute);
    } else {
      form.resetFields();
    }
  }, [initialValues]);

  const onFinish = (values: IAttribute) => {
    // console.log("Otrzymane wartości:", values);
    const formattedValues = {
      ...values,
      productionDate: values.productionDate
        ? dayjs(values.productionDate).format(dateFormat)
        : undefined,
      isRecommended: values.isRecommended ? 1 : 0,
    };
    // console.log("Otrzymane wartości:", formattedValues);
    onSave(formattedValues);
  };

  return (
    <Form
      form={form}
      name="attributeForm"
      onFinish={onFinish}
      layout="vertical"
      initialValues={initialValues ? initialValues : { isRecommended: false }}
    >
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Nazwa"
            name="name"
            rules={[{ required: true, message: "Proszę podać nazwę" }]}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            label="Cena"
            name="price"
            rules={[{ required: true, message: "Proszę podać cenę" }]}
          >
            <InputNumber min={0} step={0.01} style={{ width: "100%" }} />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        label="Opis"
        name="description"
        rules={[{ required: true, message: "Proszę podać opis" }]}
      >
        <Input.TextArea rows={3} />
      </Form.Item>

      <Row gutter={16}>
        {visibleFields.metal && (
          <Col span={12}>
            <Form.Item label="Metal" name="metal">
              <Input />
            </Form.Item>
          </Col>
        )}

        {visibleFields.goldTest && (
          <Col span={12}>
            <Form.Item label="Próba złota" name="goldTest">
              <InputNumber min={0} />
            </Form.Item>
          </Col>
        )}
      </Row>

      <Row gutter={16}>
        {visibleFields.stone && (
          <Col span={12}>
            <Form.Item label="Kamień" name="stone">
              <Input />
            </Form.Item>
          </Col>
        )}

        {visibleFields.stoneWeight && (
          <Col span={12}>
            <Form.Item label="Waga kamienia (ct)" name="stoneWeight">
              <InputNumber min={0} />
            </Form.Item>
          </Col>
        )}
      </Row>

      <Row gutter={16}>
        {visibleFields.clasp && (
          <Col span={12}>
            <Form.Item label="Zapięcie" name="clasp">
              <Input />
            </Form.Item>
          </Col>
        )}

        {visibleFields.referenceNumber && (
          <Col span={12}>
            <Form.Item label="Numer referencyjny" name="referenceNumber">
              <Input />
            </Form.Item>
          </Col>
        )}
      </Row>

      <Row gutter={16}>
        {visibleFields.diameter && (
          <Col span={12}>
            <Form.Item label="Średnica (mm)" name="diameter">
              <InputNumber min={0} />
            </Form.Item>
          </Col>
        )}

        {visibleFields.mechanism && (
          <Col span={12}>
            <Form.Item label="Mechanizm" name="mechanism">
              <Input />
            </Form.Item>
          </Col>
        )}
      </Row>

      <Row gutter={16}>
        {visibleFields.dialColor && (
          <Col span={12}>
            <Form.Item label="Kolor tarczy" name="dialColor">
              <Input />
            </Form.Item>
          </Col>
        )}

        {visibleFields.productCondition && (
          <Col span={12}>
            <Form.Item label="Stan" name="productCondition">
              <Input />
            </Form.Item>
          </Col>
        )}
      </Row>

      <Row gutter={16}>
        {visibleFields.included && (
          <Col span={12}>
            <Form.Item label="W komplecie" name="included">
              <Input />
            </Form.Item>
          </Col>
        )}

        {visibleFields.productionDate && (
          <Col span={12}>
            <Form.Item
              label="Data produkcji"
              name="productionDate"
              getValueProps={(value) => ({
                value: value ? dayjs(value, dateFormat) : null,
              })}
            >
              <DatePicker format={dateFormat} />
            </Form.Item>
          </Col>
        )}
      </Row>

      <Row gutter={16}>
        {visibleFields.color && (
          <Col span={12}>
            <Form.Item label="Kolor" name="color">
              <Input />
            </Form.Item>
          </Col>
        )}

        {visibleFields.clarity && (
          <Col span={12}>
            <Form.Item label="Czystość" name="clarity">
              <Input />
            </Form.Item>
          </Col>
        )}
      </Row>

      {visibleFields.cutType && (
        <Form.Item label="Rodzaj szlifu" name="cutType">
          <Input />
        </Form.Item>
      )}

      {/* Zawsze wysyła 1 lub 0 */}
      <Form.Item name="isRecommended" valuePropName="checked">
        <Checkbox>Polecany</Checkbox>
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit">
          Zatwierdź
        </Button>
      </Form.Item>
    </Form>
  );
}
