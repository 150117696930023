import { ColumnsType } from "antd/lib/table";
import { Button, Select } from "antd";
import { ILog } from "../../../types/ILog";
import {
  formatDate,
  getTableTypeName,
  getModalType,
} from "../../../utils/LogUtils";

const { Option } = Select;

export const getLogTableColumns = (showModal: (log: ILog) => void) => {
  return [
    {
      title: "nr",
      key: "nr",
      render: (text, record, index) => index + 1,
      align: "center",
    },
    {
      title: "Użytkownik",
      dataIndex: ["user", "username"],
      key: "username",
      align: "center",
    },
    {
      title: "Data",
      dataIndex: "date",
      key: "date",
      render: (date: number[]) => formatDate(date),
      align: "center",
    },
    {
      title: "Zmiana w",
      dataIndex: ["tableType", "name"],
      key: "tableName",
      render: (name: string) => getTableTypeName(name),
      align: "center",
    },
    {
      title: "Status",
      key: "status",
      render: (_text: any, record: ILog) => (
        <Select value={record.status.id} style={{ width: 120 }}>
          <Option value={1}>Accepted</Option>
          <Option value={2}>Deprecated</Option>
          <Option value={3}>Pending</Option>
        </Select>
      ),
      align: "center",
    },
    {
      title: "Szczegóły",
      key: "details",
      render: (_text: any, record: ILog) => (
        <Button
          type="link"
          onClick={() => showModal(record)}
          disabled={!getModalType(record.tableType.name)}
        >
          Szczegóły {getTableTypeName(record.tableType.name)}
        </Button>
      ),
      align: "center",
    },
  ] as ColumnsType<ILog>;
};
