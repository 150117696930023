import { useState, useEffect } from 'react';

const useForHim = () => {
  const [buttonText, setButtonText] = useState('POZNAJ OFERTĘ');

  useEffect(() => {
    const updateButtonText = () => {
      if (window.innerWidth <= 1341) {
        setButtonText('KUP TERAZ');
      } else {
        setButtonText('POZNAJ OFERTĘ');
      }
    };

    window.addEventListener('resize', updateButtonText);
    updateButtonText();
    return () => window.removeEventListener('resize', updateButtonText);
  }, []);

  return buttonText;
};

export default useForHim;
