import "./AppointmentSection.scss";
import Text from "../Text/Text";

export default function AppointmentSection() {
  return (
    <div className="Photo">
      <div className="dark-blur">
        <Text
          title={"UMÓW WIZYTĘ"}
          text={
            "Interesują Cię nasze produkty lub chcesz skonsultować swój wybór?\nUmów wizytę już teraz!"
          }
          showButton
          align={"text-center"}
          content={"UMÓW WIZYTĘ"}
          path={""}
          adjustableButton
        />
      </div>
    </div>
  );
}
