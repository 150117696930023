import { useEffect, useState } from "react";
import { Modal, Table } from "antd";
import { IProduct } from "../../../types/IProduct";
import { ColumnsType } from "antd/es/table";

type Props = {
  visible: boolean;
  onCancel: () => void;
  initialValues?: IProduct | null;
  loading: boolean;
};

export default function ProductDetailsModal({
  visible,
  onCancel,
  initialValues,
  loading,
}: Props) {
  const [data, setData] = useState<IProduct[]>();

  useEffect(() => {
    const valuesArray = initialValues ? [initialValues] : [];
    setData(valuesArray);
  }, [initialValues]);

  const columns: ColumnsType<IProduct> = [
    {
      title: "Opis",
      dataIndex: ["attribute", "description"],
      key: "description",
      render: (description: string | null) => description || " - ",
      align: "center",
    },
    {
      title: "Metal",
      dataIndex: ["attribute", "metal"],
      key: "metal",
      render: (metal: string | null) => metal || " - ",
      align: "center",
    },
    {
      title: "Próba Złota",
      dataIndex: ["attribute", "goldTest"],
      key: "goldTest",
      render: (goldTest: number | null) =>
        goldTest != null ? `${goldTest}` : " - ",
      align: "center",
    },
    {
      title: "Kamień",
      dataIndex: ["attribute", "stone"],
      key: "stone",
      render: (stone: string | null) => stone || " - ",
      align: "center",
    },
    {
      title: "Waga Kamienia",
      dataIndex: ["attribute", "stoneWeight"],
      key: "stoneWeight",
      render: (stoneWeight: number | null) =>
        stoneWeight != null ? `${stoneWeight} ct` : " - ",
      align: "center",
    },
    {
      title: "Zapięcie",
      dataIndex: ["attribute", "clasp"],
      key: "clasp",
      render: (clasp: string | null) => clasp || " - ",
      align: "center",
    },
    {
      title: "Numer Referencyjny",
      dataIndex: ["attribute", "referenceNumber"],
      key: "referenceNumber",
      render: (referenceNumber: string | null) => referenceNumber || " - ",
      align: "center",
    },
    {
      title: "Średnica",
      dataIndex: ["attribute", "diameter"],
      key: "diameter",
      render: (diameter: number | null) =>
        diameter != null ? `${diameter} mm` : " - ",
      align: "center",
    },
    {
      title: "Mechanizm",
      dataIndex: ["attribute", "mechanism"],
      key: "mechanism",
      render: (mechanism: string | null) => mechanism || " - ",
      align: "center",
    },
    {
      title: "Kolor Tarczy",
      dataIndex: ["attribute", "dialColor"],
      key: "dialColor",
      render: (dialColor: string | null) => dialColor || " - ",
      align: "center",
    },
    {
      title: "Stan Produktu",
      dataIndex: ["attribute", "productCondition"],
      key: "productCondition",
      render: (productCondition: string | null) => productCondition || " - ",
      align: "center",
    },
    {
      title: "Zawartość",
      dataIndex: ["attribute", "included"],
      key: "included",
      render: (included: string | null) => included || " - ",
      align: "center",
    },
    {
      title: "Data Produkcji",
      dataIndex: ["attribute", "productionDate"],
      key: "productionDate",
      render: (productionDate: string | null) => productionDate || " - ",
      align: "center",
    },
    {
      title: "Kolor",
      dataIndex: ["attribute", "color"],
      key: "color",
      render: (color: string | null) => color || " - ",
      align: "center",
    },
    {
      title: "Czystość",
      dataIndex: ["attribute", "clarity"],
      key: "clarity",
      render: (clarity: string | null) => clarity || " - ",
      align: "center",
    },
    {
      title: "Rodzaj Szlifu",
      dataIndex: ["attribute", "cutType"],
      key: "cutType",
      render: (cutType: string | null) => cutType || " - ",
      align: "center",
    },
    {
      title: "Rekomendowany",
      dataIndex: ["attribute", "recommended"],
      key: "recommended",
      render: (recommended: boolean | null) => (recommended ? "Tak" : "Nie"),
      align: "center",
    },
  ];

  return (
    <Modal
      title={`Szczegóły produktu ${initialValues?.attribute?.name}`}
      open={visible}
      onCancel={onCancel}
      footer={null}
      width={"auto"}
      style={{ whiteSpace: "nowrap" }}
    >
      <Table
        columns={columns}
        dataSource={data}
        rowKey="id"
        scroll={{ x: "100%" }}
        loading={loading}
      />
    </Modal>
  );
}
