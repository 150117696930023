import React from "react";
import './PaymentMethods.scss'
import { FaDollarSign } from "react-icons/fa6";

export default function PaymentMethods() {
    return (
<div className="payment-methods">
    <hr />
    <h1>METODY PŁATNOŚCI</h1>
    <div className="icons-row">
        <div className="icons-column">
    <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M33.1458 13.9348V29.1522M8.47917 13.9348V29.1522M3.85417 13.9348V29.1522M28.5208 13.9348V29.1522M3.08333 32.1957H33.9167M0 36H37M18.5 16.9783H16.9583C16.5495 16.9783 16.1573 17.1386 15.8682 17.424C15.5791 17.7094 15.4167 18.0964 15.4167 18.5V19.0707C15.4167 19.3999 15.5249 19.7203 15.725 19.9837C15.9251 20.2471 16.2064 20.4393 16.5267 20.5315L20.4733 21.6683C20.7936 21.7605 21.0749 21.9527 21.275 22.2161C21.4751 22.4795 21.5833 22.7999 21.5833 23.1291V24.587C21.5833 24.9905 21.4209 25.3776 21.1318 25.663C20.8427 25.9484 20.4505 26.1087 20.0417 26.1087H18.5M18.5 16.9783H20.0417C20.4505 16.9783 20.8427 17.1386 21.1318 17.424C21.4209 17.7094 21.5833 18.0964 21.5833 18.5V19.2609M18.5 16.9783V13.9348M18.5 26.1087H16.9583C16.5495 26.1087 16.1573 25.9484 15.8682 25.663C15.5791 25.3776 15.4167 24.9905 15.4167 24.587V23.8261M18.5 26.1087V29.1522M36.2292 9.75V10.8913H0.770833V9.75C8.47917 7.08696 13.1042 4.80435 18.1146 1H18.8854C23.8958 4.80435 28.5208 7.08696 36.2292 9.75Z" stroke="black" stroke-width="1.11688"/>
</svg>
<p>PRZELEW BANKOWY</p>
</div>
<div className="icons-column">
<FaDollarSign />
<p>PŁATNOŚĆ PRZY ODBIORZE</p>
</div>
</div>
</div>
    );
}
