import React, { Fragment } from "react";
import Navbar from "../Navbar/Navbar";
import Footbar from "../Footbar/Footbar";
import Main from "../Main/Main";
import { Children } from "../../interfaces/children";
import FloatingButton from "../FloatingButton/FloatingButton";

interface PageProps extends Children {
  background?: string;
}

export default function Page({ children, background }: PageProps) {
  return (
    <Fragment>
      <Navbar />
      <Main background={background}>{children}</Main>
      <Footbar />
      <FloatingButton />
    </Fragment>
  );
}
