import Text from '../Text/Text';
import AdjustableButton from '../AdjustableButton/AdjustableButton';
import useForHim from '../../hooks/useForHim';  
import './ForHim.scss';

const ForHim = () => {
  const buttonText = useForHim();  

  return (
    <div className="for-him">
      <div className="content">
        <div className="text-section">
          <Text 
            title="DLA NIEGO"
            text="Biżuteria nie musi się kojarzyć wyłącznie z kobietami. M&M Gold posiada kolekcję złotą, również z brylantami, dedykowaną męskiej części naszych klientów. Designerskie projekty oraz wyjątkowe kolekcjonerskie przedmioty mogą stanowić idealne uzupełnienie lub początek Twojej kolekcji."
            showButton={false}
            align="text-center"
            content={''}
            path={''}
          />
          <div className="button">
            <AdjustableButton
              text={buttonText}
              backgroundColor="black"
              textColor="white"
              borderColor="white"
              hoverBackgroundColor="white"
              hoverTextColor="black"
              hoverBorderColor="black"
              href=""
            />
          </div>
        </div>
        <div className="image-section">
          <img src="/images/ForHim.jpg"/>
        </div>
      </div>
    </div>
  );
}

export default ForHim;
