import React from "react";
import "./IconText.scss";
import { OpeningSectionProps } from "../../../interfaces/OpeningSectionProps";
import { ReactComponent as DiamondIcon } from "../../../icons/diamond.svg";
import { ReactComponent as CheckIcon } from "../../../icons/check.svg";
import { ReactComponent as Heart2Icon } from "../../../icons/heart2.svg";

export default function IconText({
  icon,
  title,
  description,
}: OpeningSectionProps) {
  return (
    <div className="icon-text">
      {/* <div className={`icon material-symbols-outlined icon-${icon}`}>{icon}</div> */}
      {icon === "diamond" && <DiamondIcon className="icon" />}
      {icon === "fact_check" && <CheckIcon className="icon" />}
      {icon === "favorite" && <Heart2Icon className="icon" />}

      <div className="text">
        <h3>{title}</h3>
        <p>{description}</p>
      </div>
    </div>
  );
}
