import React from "react";
import ItemCardColor from "../ItemCardColor/ItemCardColor";
import './ItemCardColorRow.scss';

interface Product {
  imageSrc: string;
  title: string;
  price: string;
  colors?: string[];
  path: string;
}

export default function ItemCardRow(props: { products: Product[] }) {
  const { products } = props;

  return (
    <div className="ItemCardRow">
      {products.map((product, index) => (
        <ItemCardColor
          key={index}
          imageSrc={product.imageSrc}
          title={product.title}
          price={product.price}
          colors={product.colors}
          path={product.path}
          variant="button"
        />
      ))}
    </div>
  );
}